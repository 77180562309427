import React from "react";
import Footerbotton from "../../components/footerBottom";
import Header from "../../components/header";
import Footer from "../../components/footer";
const RevPro = () => {
  return (
    <React.Fragment>
      <Header />
      <div className="pt-16 ">
        <div
          className="grid grid-cols-12   justify-center items-center"
          style={{
            background:
              "linear-gradient(90.07deg, #539E79 0.07%, #3A7B5E 81.22%)",
          }}
        >
          <div className="sm:col-span-6 col-span-5">
            <img
              className="sm:pb-0 pb-5 sm:mt-0 mt-5"
              src="/rev-mobile.png"
              alt=""
            />
          </div>
          <div className="col-span-6 sm:mt-0 mt-5">
            <h1 className="font-[500] text-white  text-[20px] sm:text-[40px]">
              RevPro
            </h1>
            <p className="sm:text-2xl text-[14px] pb-10 font-[400] text-white">
              UI/UX Design Case study
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 px-5">
          <div className="max-w-[1040px] w-[100%] m-auto">
            <p className="font-[700] text-[20px]  sm:text-[26px] mt-[20px]  sm:mt-[103px]">
              Project Overview
            </p>
            <p
              className="font-[400] text-[14px] sm:text-[18px] mt-5 sm:mt-[20px]"
              style={{ lineHeight: "27px" }}
            >
              Revpro is an Enterprise (non public facing) centralized billing
              and revenue management system built to automate revenue collection
              and Treasury Single Account TSA in Nigerian states and local
              governments (LGA).
              <br />
              <br /> Revpro is designed to provide revenue management solutions
              to both the formal and informal sectors in Nigeria. With an
              omnichannel experience, the web and mobile based applications are
              designed to aid the collection and payments for government
              services and taxes, scan and verify invoices, remit collected cash
              payments to the government accounts as well as, provide the
              history of all financial transactions while handling Settlements
              and Reconciliation through its reporting module in order to
              maintain transparency and accountability.
              <br />
              <br /> With RevPro, governments and their relevant agencies are
              better able to track, monitor and manage the revenue they receive
              from different sources ministries, departments, agencies (MDA)
              owing to modules that provide real-time visibility for all parties
              involved through a comprehensive report of all transactions made
              including cash, credit card and cashless payments.
            </p>
            <p className="font-[700]  text-[16px] sm:text-[26px] my-[13px] sm:mt-[49px]">
              Features
            </p>
            <ul className="list-disc ml-5 font-[600] text-[14px] sm:text-[18px]">
              <li>Revenue Management and Collection on the go:</li>
            </ul>
            <p className="mt-5 text-[14px] sm:text-[18px]">
              {" "}
              RevPro aids governments in managing and administrating their
              revenue using the following features:
            </p>
            <ol className="list-decimal sm:ml-14 ml-5 mt-5 text-[14px]  sm:text-[18px]">
              <li>E-Invoicing and E-Receipting.</li>
              <li>Payment splitting and settlement management.</li>
              <li>Remittance Management.:</li>
            </ol>
            <ul className="list-disc ml-5 mt-5 font-[600] text-[14px] sm:text-[18px]">
              <li>Taxpayer Information Management</li>
            </ul>
            <p className="text-[14px] sm:text-[18px] mt-5 font-[400]">
              The taxpayer information management module of RevPro is firmly
              integrated with a tax entity information extension to effectively
              collect and connect various tax-paying entities.
            </p>
            <ul className="list-disc ml-5 mt-5 font-[600] text-[14px] sm:text-[18px]">
              <li>Mobile and Mobile POS Revenue Collection Module</li>
            </ul>
            <p className="text-[14px] sm:text-[18px] mt-5 font-[400]">
              The mobile POS application is an Android application that is
              installable on mobile phones and Android mobile POS. The mobile
              POS applications works online and offline to generate and print
              e-invoices and e-receipts.
            </p>

            <ul className="list-disc ml-5 mt-5 font-[600] text-[14px] sm:text-[18px]">
              <li>System Administration Module </li>
            </ul>
            <p className="text-[14px] sm:text-[18px]  mt-5 font-[400]">
              Provides the base administration functions for the entire
              application. It houses the security features, user and user
              privilege management, approval workflows, database backup,
              recovery and more.
            </p>

            <ul className="list-disc ml-5  mt-5 font-[600] text-[14px] sm:text-[18px]">
              <li>Advanced live monitoring and reporting</li>
            </ul>
            <p className="text-[14px] sm:text-[18px]  mt-5 font-[400]">
              The system provides for real-time monitoring, evaluating and
              reporting of live transactions as it occurs through the help of a
              dashboard.
            </p>
            <div className="mt-10">
              <img
                src="/multi-mobile.png"
                alt=""
                className="max-w-[828px] w-[100%] m-auto h-auto"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Footerbotton />
    </React.Fragment>
  );
};

export default RevPro;
