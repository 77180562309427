import React from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Footerbotton from "../../components/footerBottom";
import { tableData } from "../../data";
// import examplePDF from "../../../public/cv.pdf";
const About = () => {
  const handlePreview = () => {
    window.open("/cv.pdf", "_blank");
  };
  return (
    <React.Fragment>
      <Header />
      <div className="container m-auto">
        <div className="sm:py-[24px] py-[16px]">
          <div className="w-[100%] m-auto pt-24 px-5">
            <div className="flex justify-center flex-wrap gap-4 max-w-[1824px]">
              <div className="flex-shrink-0 
              w-[231px] h-[270px] sm:w-[308px] sm:h-[360px]">
                <img src="Mask group.png" alt="" className="" />
              </div>
              <div className="flex-grow max-w-[800px]">
                <div className="flex justify-between flex-col h-[100%]">
                  <div>
                    <p className="sm:text-[20px] text-[14px] sm:mt-0 mt-4 mb-5 font-[400]">
                      HEY THERE,
                    </p>
                    <p className="sm:text-[25px] text-[22px] font-bold ">
                      I am Victoria Onogu
                    </p>
                    <p className="sm:text-[20px] text-[14px] font-[400] mt-0 sm:mt-20 md:mt-[2px] lg:mt-5 my-5">
                      I have an educational background in Architecture
                      where I learned about designs, functionality,
                      aesthetics and how to meet the needs of clients/users and because of this acquired knowledge,
                      i am passionate about functional, yet easy to use (user centric) designs, i
                      think about the product and the user journey through it. I employ empathy to
                      understand the user needs. I am always looking for the next big thing in designs from emerging concepts to trends and how to implement it.
                    </p>
                  </div>
                  <div className="">
                    <div
                      // to="/cv"
                      onClick={handlePreview}
                      className="bg-[#0D99FF] text-white border-2 w-fit cursor-pointer
                      text-[14px] sm:text-[20px]
                      rounded-xl  sm:rounded-xl
                      py-2    px-8
                      sm:py-3 sm:px-12
                      "
                    >
                      View CV
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-[25px] sm:mt-[5px] grid grid-cols-12  rounded-xl bg-[#E9F4FF] mx-4 md:mx-52 lg:mx-72">
            <div className="sm:col-span-3 col-span-6  sm:hidden">
              <div className=" text-[18px] sm:text-[26px]  font-[600]">
                <p className=" py-[10px] pl-4 "> Skills</p>
              </div>
              <div className="sm:col-span-3 col-span-6  sm:hidden">
                <div className="text-[26px]   font-[600]">
                  <p className="sm:text-[18px] pl-4  text-[12px]  py-1 font-[600]">
                    UI Design
                  </p>
                </div>
                <div className="text-[26px]   font-[600]">
                  <p className="sm:text-[18px] pl-4  text-[12px]   py-2 font-[600]">
                    {" "}
                    UX Design
                  </p>
                </div>
                <div className="text-[26px]   font-[600]">
                  <p className="sm:text-[18px] pl-4 text-[12px]  py-2 font-[600]">
                    Branding
                  </p>
                </div>
                <div className="text-[26px]   font-[600]">
                  <p className="sm:text-[18px] pl-4 text-[12px]   py-2 font-[600]">
                    Prototyping
                  </p>
                </div>
              </div>
            </div>
            <div className="sm:col-span-3 col-span-6 mt-16  sm:hidden">
              <div className="text-[26px]   font-[600]">
                <p className="sm:text-[18px]  text-[12px]   py-1 font-[600]">
                  Wireframing
                </p>
              </div>
              <div className="text-[26px]   font-[600]">
                <p className="sm:text-[18px]  text-[12px]   py-2 font-[600]">
                  {" "}
                  Graphics Design{" "}
                </p>
              </div>
              <div className="text-[26px]   font-[600]">
                <p className="sm:text-[18px]  text-[12px]  py-2 font-[600]">
                  2D/3D Drawings
                </p>
              </div>
            </div>
          </div>
          <div className=" grid grid-cols-12  sm:mt-10 mt-5 rounded-xl bg-[#E9F4FF]  mx-4 md:mx-52 lg:mx-72  ">
            <div className=" sm:col-span-9 col-span-12   sm:hidden">
              <div className="text-[18px] sm:text-[26px]  font-[600]">
                <p className=" pl-4  py-[10px] ">Tools</p>
              </div>

              {tableData.map((item, index) => (
                <div className="text-[26px] justify-between space-x-5 my-[1px] px-[9px] items-center  flex  font-[600]">
                  <img
                    className="sm:w-[50px]  sm:h-[58px] w-[38px]  sm:py-[4px] py-[2px] "
                    src={item.image}
                    alt=""
                  />
                  <p className="text-[12px] w-[150px]  text-center py-3 font-[600]">
                    {item.figma}
                  </p>
                  <div class="w-full rounded-full h-3 bg-[#A6CCE8]">
                    <div
                      class="bg-[#0D99FF] h-3 rounded-full "
                      style={{ width: item.percentage }}
                    ></div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className=" grid grid-cols-12  rounded-xl bg-[#E9F4FF]  mx-7 md:mx-52 lg:mx-72  ">
            <div className="sm:col-span-3 col-span-12 border-r hidden sm:block">
              <div className="text-[26px]  font-[600]">
                <p className="text-center py-[10px] border-b "> Skills</p>
              </div>

              {tableData.map((item, index) => (
                <div className="text-[26px]   font-[600]">
                  <p className="text-[18px]   text-center py-4 border-b font-[600]">
                    {item.name}
                  </p>
                </div>
              ))}
            </div>
            <div className=" sm:col-span-9 col-span-12  border-b hidden sm:block pr-4">
              <div className="text-[26px]  font-[600]">
                <p className=" text-center  py-[10px] border-b">Tools</p>
              </div>

              {tableData.map((item, index) => (
                <div className="text-[26px] justify-between space-x-5 my-[1px] px-[9px] items-center border-b flex  font-[600]">
                  <img className="w-[50px] h-[58px]  py-2 " src={item.image} alt=""/>
                  <p className="text-[18px] w-[150px]  text-center py-3 font-[600]">
                    {item.figma}
                  </p>
                  <div class="w-full rounded-full h-4 bg-[#A6CCE8]">
                    <div
                      class="bg-[#0D99FF] h-4 rounded-full "
                      style={{ width: item.percentage }}
                    ></div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Footerbotton />
    </React.Fragment>
  );
};

export default About;
