import React from "react";
import { icon } from "../../data";
import MapOne from "../../components/mapOne";
import MapTwo from "../../components/mapTwo";
import Useablity from "../../components/useability";
import Empathize from "../../components/empathize";
import ChartTable from "../chart/chart";
import Calabs1 from "./../../components/calabs1";
const Project = () => {
  return (
    <React.Fragment>
      <div className=" sm:px-5 px-6">
        <div className="grid sm:px-5 px-3 grid-cols-1 ">
          <div className="max-w-[1040px] w-[100%] m-auto sm:mt-[50px] mt-5">
            <p className="sm:text-[24px] text-[20px] sm:mt-4 mt-7 font-[700] ">
              Project Overview
            </p>
            <p className="font-[600] text-[18px] sm:text-[22px] mt-[8px]">
              Project Brief:
            </p>
            <p
              className="font-[400] sm:text-[18px] text-[14px] mt-1"
              style={{ lineHeight: "27px" }}
            >
              Swap home is an app and web based platform that connects travelers
              looking to exchange homes for the holidays, vacations and weekends
              both locally and internationally at no cost. It also gives the
              experience of a home away from home by providing amenities that
              are not readily available in hotel services. With an extra feature
              called Book Home, it also connects users who wants to become a
              host to travelers who would prefer to book a home instead of a
              hotel for their vacation.
            </p>
            <p className="font-[600] text-[18px] sm:text-[22px] mt-[20px]">The Problem:</p>
            <p
              className="font-[400] sm:text-[18px] text-[14px] mt-[2px]"
              style={{ lineHeight: "27px" }}
            >
              According to Statistics.com, about 917 million international
              travels happened in 2022, and with this huge number of tourism
              across the globe, it is no surprising that there is an increasing
              hike in the prices of hotels/ accommodation, especially in the
              most visited parts of the world, hence, making vacationing more
              expensive. Home exchange services, is an underutilized market with
              a lot of business potential but sadly, some of the few companies
              offering this services do not have a user friendly design and
              business approach, hence, making it less reliable and cumbersome
              for some potential users.
            </p>
            <p className="font-[600] text-[18px] sm:text-[22px] mt-[18px]">Goal:</p>
            <ul className="list-disc mt-[2px] sm:text-[18px] text-[14px]">
              <p>
                Listed below are some of the goals of{" "}
                <span className="font-bold"> Swap Home</span> Project:
              </p>
            </ul>
            <ul className="list-disc ml-5 mt-[2px] sm:text-[18px] text-[14px]">
              <li className="">
                A platform that connects travelers/tourists who would like to
                exchange their homes to each other for the duration of their
                stay at no cost.
              </li>
              <li>
                A platform that allows home owners make some money by short
                lenting (Hosting) their homes to guests for the duration of the
                agreed period.
              </li>
              <li>
                A platform that would only allow homes that have been verified
                to be safe and in a safe neighborhood on their site.
              </li>
              <li>
                A platform that would put measures in place to protect the
                properties of the home owners in their absent and also make sure
                occupants who damage or destroy anything is held liable.
              </li>
              <li>
                A platform that will use the information provided by the user to
                understand the needs of the user and in turn, provide relevant
                content that is tailored to the user.
              </li>
            </ul>
            <p className="sm:text-[24px] text-[20px] sm:mt-4 font-[700] mt-10">The Process</p>
            <p className="font-[600] text-[18px] sm:text-[22px] mt-[8px]">Empathize:</p>
            <Empathize />
          </div>
        </div>
      </div>

      <MapOne />
      <MapTwo />
      <Calabs1 />

      <div className=" sm:px-5 px-6 max-w-[1040px] w-[100%] m-auto">
        <Useablity />
        <div className="py-6">
          <p className="text-center sm:text-[26px] text-[12px] font-[600] ">
            Usability Testing Results Comparison
          </p>
        </div>
        <div className="grid grid-cols-1">
          <div className="max-w-[600px] w-[100%] m-auto flex justify-between items-center">
            <p className="font-[600]">Age:</p>
            <div className="sm:w-[75px] sm:h-[65px] w-[30px] h-[30px] bg-[#23395d] rounded-lg"></div>

            <p>20-35</p>
            <div className="sm:w-[75px] sm:h-[65px] w-[30px] h-[30px] bg-[#6e5a7d] rounded-lg"></div>

            <p>35-50</p>
            <div className="sm:w-[75px] sm:h-[65px] w-[30px] h-[30px] bg-[#59b2a9] rounded-lg"></div>
            <p>50-65</p>
          </div>
        </div>
        <ChartTable />
        <p className="text-[18px]  text-center sm:mt-10 mt-4 font-[400] text-[#B3B3B3]">
          Usability Test Graph
        </p>
        <p className="sm:text-[18px] text-[14px] sm:mt-10 mt-4 font-[400]">
          I used both the quantitative and qualitative usability testing methods
          for maximum results. Below are some of the results:
        </p>
        <ul className="list-disc ml-5  mt-1 font-[400] sm:text-[18px] text-[14px]">
          <li>
            90% of the users completed the create account scenario without
            assistance.{" "}
          </li>
          <li>
            90% of the users were able to complete certain tasks within an
            estimated period of time while 10% struggled to complete the task.
          </li>
          <li>
            Users wants to be able to set their own cancellation policies.
          </li>
        </ul>
        <p className="sm:text-[24px] text-[20px] sm:mt-10 font-[700] mt-8">
          UI Components:
        </p>
        <p className="font-[600] text-[18px] sm:text-[22px] mt-[8px]">
          Design Guide:
        </p>
        <p className="sm:text-[18px] text-[14px] mt-1 font-[400]">
          This testing was carried out before the final implementation stage and
          10 users ranging from 21 to 60 years of age ( 5 online
          <br /> users and 5 family & friends) were contacted for this process.
          The aim of this testing was to understand how the users
          <br /> interact with the application and to improve and implement
          feedbacks for better user experience.
        </p>
        <p className="sm:text-[26px] text-[20px] mt-10 font-[700]">Colors:</p>
        <p className="sm:text-[22px] text-[18px] mt-1 font-[400]">Navy Blue:</p>
        <p className="sm:text-[18px] text-[14px] mt-1 font-[400]">
          This color represents Stability, Reliability and confidence.
        </p>
        <p className="sm:text-[22px] text-[18px] mt-1 font-[400]">Orange: </p>
        <p className="sm:text-[18px] text-[14px] mt-1 font-[400]">
          This color represents Happiness, Determination, energy, Success and it
          was used for this design because it also inspires creativity and
          uplift people’s mood.
        </p>
        <div className="bg-[#FFFBFA] sm:mt-20 mt-5">
          <div className="grid grid-cols-12 ">
            <div className="col-span-6 justify-center pt-2">
              <p className="sm:text-[22px] text-[14px] font-[400] justify-center py-5  text-center">
                Secondary Colors
              </p>

              <div className="grid grid-cols-12 space-x-3 ">
                <div className="col-span-6 lg:px-32 md:px-20  sm:px-10  px-2 mb-10 ">
                  <div className="bg-[#23395D] text-white rounded-full flex justify-center sm:text-[16px] text-[14px]  items-center  lg:w-[116px] md:w-[80px] sm:w-[70px] w-[60px]  lg:h-[116px] md:h-[80px] sm:-[70px] h-[60px]">
                    {" "}
                    #233
                    <br className="sm:hidden block" />
                    95D
                  </div>
                </div>
                <div className=" col-span-6 ">
                  <div className="bg-[#ADBEDA] rounded-full flex justify-center sm:text-[16px] text-[14px] items-center lg:w-[116px] md:w-[80px] sm:w-[70px] w-[60px]  lg:h-[116px] md:h-[80px] sm:-[70px] h-[60px]  ">
                    {" "}
                    #ADB <br className="sm:hidden block" />
                    EDA
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-6 justify-center pt-2">
              <p className="sm:text-[22px] text-[14px] font-[400] justify-center py-5  text-center ">
                Secondary Colors
              </p>

              <div className="grid grid-cols-12  space-x-3  ">
                <div className="col-span-6 lg:px-32 md:px-20  sm:px-10  px-2 mb-10">
                  <div className="bg-[#E99877] rounded-full flex justify-center items-center  sm:text-[16px] text-[14px]  items-center  lg:w-[116px] md:w-[80px] sm:w-[70px] w-[60px]  lg:h-[116px] md:h-[80px] sm:-[70px] h-[60px] ">
                    #E99
                    <br className="sm:hidden block" />
                    877
                  </div>
                </div>
                <div className=" col-span-6 ">
                  <div className="bg-[#FFEEEA] rounded-full flex justify-center items-center sm:text-[16px] text-[14px]  items-center  lg:w-[116px] md:w-[80px] sm:w-[70px] w-[60px]  lg:h-[116px] md:h-[80px] sm:-[70px] h-[60px]  ">
                    #FFE
                    <br className="sm:hidden block" />
                    EEA
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p className="text-[18px] text-center mt-10 font-[400] text-[#B3B3B3]">
          Color Palette
        </p>
        <p className="sm:text-[26px] text-[16px] mt-10 font-[700]">
          Typography:
        </p>
        <div className="grid grid-cols-12 sm:space-x-5 space-x-2 items-center">
          <div className="col-span-3 ">
            <p className="sm:text-[80px] text-[40px] mt-1 font-[400]">Aa</p>
            <p className="sm:text-[22px] text-[14px] mt-1 font-[400]">
              Open sans
            </p>
          </div>
          <div className="col-span-9 ">
            <p className="font-[400] sm:text-[26px] text-[16px] mt-7">
              Aa Bb Cc Dd Ee Ff Gg Hh Ii Jj Kk Ll Mm Nn Oo Pp Qq Rr Ss Tt Uu Vv{" "}
              <br /> Ww Xx Yy Zz
            </p>
            <p className="font-[400] sm:text-[22px] text-[14px] mt-2">
              Aa Bb Cc Dd Ee Ff Gg Hh Ii Jj Kk Ll Mm Nn Oo Pp Qq Rr Ss Tt Uu Vv
              Ww Xx Yy Zz
            </p>
            <p className="font-[400] sm:text-[18px] text-[12px] mt-3">
              Aa Bb Cc Dd Ee Ff Gg Hh Ii Jj Kk Ll Mm Nn Oo Pp Qq Rr Ss Tt Uu Vv
              Ww Xx Yy Zz
            </p>
            <p className="mt-3 flex">
              <span className="font-[700] sm:text-[22px] text-[14px]">
                {" "}
                Bold{" "}
              </span>{" "}
              <span className="font-[600] sm:text-[22px] text-[14px] sm:px-3 px-2">
                Semibold
              </span>{" "}
              <span className="font-[400] sm:text-[22px] text-[14px] sm:px-3 px-2">
                Regular
              </span>
              <span
                className="font-[400] sm:text-[22px] text-[14px] sm:px-3 px-2"
                style={{ fontStyle: "italic" }}
              >
                Italic
              </span>
            </p>
          </div>
        </div>
        <div className="grid grid-cols-12 space-x-5 items-center">
          <div className="col-span-3 ">
            <p className="sm:text-[26px] text-[16px] mt-1 font-[700]">
              Branding:
            </p>
            <p className="sm:text-[22px] text-[14px] mt-1 font-[400]">Logo</p>
          </div>
          <div className="col-span-9 ">
            <div>
              <img src="/shap.PNG" alt="graph" />
            </div>
          </div>
        </div>
        <div className="flex ">
          <div className="  w-[215px] sm:text-[26px] text-[16px] mt-1 font-[700]">
            Icons:
          </div>
          <div className=" flex " style={{ flexFlow: "wrap", width: "610px" }}>
            {icon.map((item, index) => (
              <div className="sm:w-[50px] w-[30px] sm:h-[50px] h-[30px] flex justify-center items-center">
                <img src={item} alt="graph" className="p-2" />
              </div>
            ))}
          </div>
        </div>
        <p className="sm:text-[26px] text-[16px] mt-8 font-[700]">
          Conclusion:
        </p>
        <div className="sm:text-[18px] text-[14px] mt-5 font-[400] ">
          <p className="sm:text-[18px] text-[14px] mt-5 font-[400] ">
            The main aim and challenge of this project was to proffer an
            alternative to the increasing hike in accommodation prices that
            travelers and tourist face during vacation or holidays. With this
            project, i was able to come up with possible solutions by:
          </p>
          <ul className="list-disc ml-5 mt-1 font-[400] sm:text-[18px] text-[14px]">
            <li>
              Creating a user centric website and mobile application, easy to
              understand and navigate through.
            </li>
            <li>
              Creating a platform that can either allow travelers swap their homes
              at no cost at all or book homes at an affordable
              <br /> price.
            </li>
            <li>
              A platform that allows home owners earn some money from short
              lenting their homes to travelers/tourists.
            </li>
          </ul>
          <div className="mt-6">
            <p className="sm:text-[18px] text-[14px] mt-5 font-[400] ">
              Listed below are some of the goals of <strong>Swap Home</strong> Project:
            </p>
            <ul className="list-disc ml-5 mt-1 font-[400] sm:text-[18px] text-[14px]">
              <li>
                A platform that connects travelers/tourists who would like to exchange their homes to 
                each other for the duration of their stay at no cost.
              </li>
              <li>
                A platform that allows home owners make some money by short lenting (Hosting) their homes 
                to guests for the duration of the agreed period.
              </li>
              <li>
                A platform that would only allow homes that have been verified to be safe and in a safe neighborhood on their site.
              </li>
              <li>
                A platform that would put measures in place to protect the properties of the home owners in their 
                absent and also make sure occupants who damage or destroy anything is held liable.
              </li>
              <li>
                A platform that will use the information provided by the user to understand the needs of the user
                 and in turn, provide relevant content that is tailored to the user.
              </li>
            </ul>
          </div>
          <div className="mt-6">
            <p>
              From research carried out during the testing phase of this project, as compared to the few existing home exchange platforms:
            </p>
            <ul className="list-disc ml-5 mt-1 font-[400] sm:text-[18px] text-[14px]">
              <li>
                90% of the user test group liked that the app allows for both swapping and booking of homes in one appilcation.
              </li>
              <li>
                95% of user test group liked that it is both web and mobile based for convenience.
              </li>
              <li>
              Because the app allows to save data locally, users can access their reservations locally in case they can not access the internet services.
              </li>
            </ul>
            <p>
             <strong>Hence</strong> Increase in both retention and conversion rate.
            </p>
            <p className="mt-5">
              As the sole designer for this project, the main challenge i faced was researching and gathering datas. 
              With the vast untapped potential of Home exchange services, i had to carry out extended research in person, 
              understanding the pros, the cons, the risks and what measures should be implemented to protect home owners 
              and guest equally.
              <br />
              During the course of this research, i met with other designers on ADPList Mentoring platform to share ideas 
              and collaborate with. I learnt that active communication with other designers and constructive criticism 
              were one of the keys to ensuring the project’s success. 
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Project;
