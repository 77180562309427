import "./App.css";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import React from "react";
import Error from "./pages/404";
import Home from "./pages/home";
import About from "./pages/about";
import RevPro from "./pages/rev-pro";
import HomeSwap from "./pages/homeSwap";
import Fitness from "./pages/fitness";
import Prototype from "./pages/viewprototype";
import Fitnessprototype from "./pages/fitnessprototype";
function App() {
  return (
    <React.Fragment>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/rev-pro" element={<RevPro />} />
          <Route path="/homeswap" element={<HomeSwap />} />
          <Route path="/fitness" element={<Fitness />} />
          <Route path="/prototype" element={<Prototype />} />
          <Route path="/fitnessPrototype" element={<Fitnessprototype />} />
          <Route path="*" element={<Error />} />
          {/* <Route path="*" element={<Navigate to="/404.html" replace={false}/>} /> */}
{/* 
          <Route path="*" element={() => {
            return (
            <div>
              <script>
                window.location.href="/404.html"
              </script>
            </div>
            );
          }} /> */}


          {/* <Route path="/rt" render={() => {window.location.href="/404.html"}} /> */}
        </Routes>
      </Router>
    </React.Fragment>
  );
}

export default App;
