import React from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Footerbotton from "../../components/footerBottom";
import { useNavigate } from "react-router-dom";
const Home = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Header />
      <div className="container m-auto">
        <div className="sm:pt-36 pt-20">
          <div className="max-w-[868px] w-[100%] m-auto   px-7">
            <p className=" md:font-[700] sm:text-[20px] text-[12px] font-bold">
              Hello, my name is{" "}
              <span
                className="font-semibold"
                style={{ background: "rgba(29, 126, 169, 0.2)" }}
              >
                Victoria Onogu
              </span>
            </p>
            <p className="font-[500] md:text-[37px] text-[17px]">
              I am a ui/ux designer with over 4 years experience who is
              <span style={{ background: "rgba(254, 177, 5, 0.1)" }}>
                {" "}
                passionate about user-centric designs.
              </span>
            </p>
            <p className=" text-[#D9D9D9] text-[12px] font-[500] sm:mt-10 mt-5 md:hidden ">
              {" "}
              MY RECENT WORK
            </p>
          </div>
          <div
            onClick={() => {
              navigate("/fitness");
              window.scrollTo(0, 0);
            }}
            className="grid cursor-pointer sm:h-[400px] h-[200px]  grid-cols-12 sm:mx-20 mx-4 ms:space-x-5 bg-[#59B2A9]
            rounded-xl  items-center md:mt-[130px] mt-[10px] hover:drop-shadow-xl hover:contrast-125"
          >
            <div className="col-span-5 sm:px-5 px-2">
              <div className="flex justify-center">
                <img
                  src="/mobile.png"
                  alt=""
                  className="h-auto sm:w-[150px] w-[80px]"
                />
              </div>
            </div>
            <div className="col-span-7 sm:px-5 px-2">
              <div className="flex" style={{ flexDirection: "column" }}>
                <h2 className="font-semibold md:text-[40px] text-[16px]">
                  Fitness Buddies
                </h2>
                <p className="max-w-[420px] md:text-lg text-[11px]">
                  Fitness buddies is a mobile application that shows users
                  profile of other users within a certain radius and it allows
                  users swipe right and connect with another user who have
                  similar goals and also someone they think can push them to
                  meet their set goals and verse versa.
                </p>
              </div>
            </div>
          </div>

          <div
            onClick={() => {
              navigate("/homeswap");
              window.scrollTo(0, 0);
            }}
            className="grid cursor-pointer grid-cols-12 sm:mx-20 mx-4 sm:h-[400px] h-[200px] bg-[#EB8A73] 
            rounded-xl items-center lg:mt-[84px] mt-[20px] hover:drop-shadow-xl hover:contrast-125"
          >
            <div className="col-span-5 relative">
              <img
                src="/laptop5.png"
                alt=""
                className="px-2 lg:px-20 md:px-6 sm:px-4 m-auto"
              />
            </div>
            <div className="col-span-7 sm:px-5 px-2">
              <div className="flex" style={{ flexDirection: "column" }}>
                <h2 className="font-semibold md:text-[40px] text-[16px]">
                  Swap Home
                </h2>
                <p className="max-w-[400px] md:text-lg text-[11px] ">
                  Swap home is an app and web based platform that connects
                  travelers looking to exchange homes for the holidays,
                  vacations and weekends both locally and internationally at no
                  cost.
                </p>
              </div>
            </div>
          </div>

          <div
            onClick={() => {
              navigate("/rev-pro");
              window.scrollTo(0, 0);
            }}
            className="grid cursor-pointer grid-cols-12 sm:mx-20 mx-4 sm:h-[400px] h-[200px] bg-[#539E79] rounded-xl 
            items-center lg:mt-[84px] mt-[20px] hover:drop-shadow-xl hover:contrast-125"
          >
            <div className="col-span-5">
              {/* <div className="flex justify-center "> */}
              <img
                src="/revpro.png"
                alt=""
                className="h-auto sm:w-[150px] w-[80px] m-auto"
              />
              {/* </div> */}
            </div>
            <div className="col-span-7 sm:px-5 px-2">
              <div className="flex" style={{ flexDirection: "column" }}>
                <h2 className="font-semibold md:text-[40px] text-[16px]">
                  RevPro
                </h2>
                <p className="max-w-[400px] md:text-lg text-[11px]">
                Revpro is an Enterprise (non public facing) centralized billing
              and revenue management system built to automate revenue collection
              and Treasury Single Account TSA in Nigerian States and Local
              governments.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <Footer />
      </div>

      <Footerbotton />
    </React.Fragment>
  );
};

export default Home;
