export const tableData = [
  {
    name: "Ul Design ",
    figma: "Figma",
    image: "/Capture.PNG",
    percentage: "85%",
  },
  {
    name: "UX Design ",
    figma: "Photoshop",
    image: "/ps.png",
    percentage: "55%",
  },
  {
    name: "Branding",
    figma: "Illustrator",
    image: "/ai.png",
    percentage: "85%",
  },
  {
    name: "Prototyoing",
    figma: "Abobe Xd",
    image: "/xd.png",
    percentage: "85%",
  },
  {
    name: "WireFraming",
    figma: "Webflow",
    image: "/w.png",
    percentage: "40%",
  },
  {
    name: "Graphic Designing  ",
    figma: "Sketchup Pro",
    image: "/sketch.png",
    percentage: "85%",
  },
  {
    name: "2D/3D Drawings ",
    figma: "AutoCAD",
    image: "/a.png",
    percentage: "85%",
  },
];
export const mobile = [
  { mobile: "/splash (3) 1.png" },
  { mobile: "/Onboarding 3.png" },
  { mobile: "/Onboarding 1 1.png" },
  { mobile: "/Onboarding 2 1.png" },
  { mobile: "/Sign in 1.png" },
  { mobile: "/Sign Up 1.png" },
  { mobile: "/Email Verification (1) 1.png" },
  { mobile: "/Profile Location) 1.png" },
  { mobile: "/Profile (Personal data) 1.png" },
  { mobile: "/Profile (Intrerest) (1) 1.png" },
  { mobile: "/ID Verification 1.png" },
  { mobile: "/Verifcation complete 1.png" },
  { mobile: "/1.PNG" },
  { mobile: "/2.PNG" },
  { mobile: "/3.PNG" },
  { mobile: "/4.PNG" },
  { mobile: "/5.PNG" },
  { mobile: "/6.PNG" },
  { mobile: "/7.PNG" },
  { mobile: "/8.PNG" },
  { mobile: "/9.PNG" },
  { mobile: "/10.PNG" },
  { mobile: "/11.PNG" },
  { mobile: "/12.PNG" },
  { mobile: "/13.PNG" },
  { mobile: "/14.PNG" },
  { mobile: "/15.PNG" },
];
export const prototypemobile = [
  { mobile: "/1.PNG" },
  { mobile: "/2.PNG" },
  { mobile: "/3.PNG" },
  { mobile: "/4.PNG" },
  { mobile: "/5.PNG" },

  { mobile: "/8.PNG" },
  { mobile: "/7.PNG" },

  { mobile: "/9.PNG" },
  { mobile: "/10.PNG" },

  { mobile: "/11.PNG" },
  { mobile: "/6.PNG" },
  { mobile: "/12.PNG" },
  { mobile: "/13.PNG" },
  { mobile: "/14.PNG" },
  { mobile: "/15.PNG" },
  { mobile: "/65.PNG" },
];
export const fitnessmobile = [
  { mobile: "/Open App 1 (1).png" },
  { mobile: "/Splash Screen 1.png" },
  { mobile: "/Sign in 1.png" },
  { mobile: "/Sign Up 2 (2).png" },
  { mobile: "/Email Verification 1.png" },
  { mobile: "/Onboarding 4 (1).png" },
  { mobile: "/Profile Gender) 1.png" },
  { mobile: "/Profile (Age) 1.png" },
  { mobile: "/Profile (Hieght) 1.png" },
  { mobile: "/Profile (Weight) 1.png" },
  { mobile: "/Profile (Allergies) 1.png" },
  { mobile: "/Profile( Goal) 1.png" },
  { mobile: "/Workout level 1.png" },
  { mobile: "/Write Bio 1.png" },
  { mobile: "/Profile Location) 1.png" },
  { mobile: "/Profile complete.png" },
  { mobile: "/Discover Users 1 (3).png" },
  { mobile: "/Swiped Right 1 (2).png" },
  { mobile: "/m27.PNG" },
  { mobile: "/m2.PNG" },
  { mobile: "/m3.PNG" },
  { mobile: "/m4.PNG" },
  { mobile: "/m5.PNG" },
  { mobile: "/m6.PNG" },
  { mobile: "/m7.PNG" },
  { mobile: "/m8.PNG" },
  { mobile: "/m9.PNG" },
  { mobile: "/m10.PNG" },
  { mobile: "/m11.PNG" },
  { mobile: "/m12.PNG" },
  { mobile: "/m13.PNG" },
  { mobile: "/m14.PNG" },
  { mobile: "/m15.PNG" },
  { mobile: "/mm29.PNG" },
  { mobile: "/m24.PNG" },
  { mobile: "/mm30.PNG" },

  { mobile: "/m18.PNG" },
  { mobile: "/m19.PNG" },
  { mobile: "/m20.PNG" },
  { mobile: "/m21.PNG" },
  { mobile: "/m22.PNG" },
  { mobile: "/m23.PNG" },
  { mobile: "/m16.PNG" },
  { mobile: "/m17.PNG" },
];
export const fitnesprototypesmobile = [
  { mobile: "/m27.PNG", title: "Open App" },
  { mobile: "/m2.PNG", title: "Splash screen" },
  { mobile: "/m3.PNG", title: "Splash screen" },
  { mobile: "/m4.PNG", title: "Sign in" },
  { mobile: "/m5.PNG", title: "Sign Up" },
  { mobile: "/m6.PNG", title: "Email Verifaction" },
  { mobile: "/m7.PNG", title: "Splash screen" },
  { mobile: "/m8.PNG", title: "google mail" },
  { mobile: "/m9.PNG", title: "Verify mail" },
  { mobile: "/m4.PNG", title: "Sign in" },
  { mobile: "/m10.PNG", title: "Onboarding" },
  { mobile: "/m11.PNG", title: "Onboarding 1" },
  { mobile: "/m12.PNG", title: "Onboarding 2" },
  { mobile: "/m13.PNG", title: "Onboarding 3" },
  { mobile: "/m14.PNG", title: "Profile Gender" },
  { mobile: "/m15.PNG", title: "Prifle (Height)" },
  { mobile: "/mm29.PNG", title: "Profile (Weight)" },
  { mobile: "/m24.PNG", title: "Profile Allergies" },
];
export const swapData = [
  { mobile: "/mm30.PNG", title: "Profile (Goal)" },

  { mobile: "/m18.PNG", title: "Workout level" },
  { mobile: "/m19.PNG", title: "Write Bio" },
  { mobile: "/m20.PNG", title: "Profile (Location)" },
  { mobile: "/m21.PNG", title: "Edit Profile" },
  { mobile: "/m22.PNG", title: "Profile Complete" },
  { mobile: "/m23.PNG", title: "Discover Users" },
  { mobile: "/m16.PNG", title: "Discover Users" },
  { mobile: "/m17.PNG", title: "Swiped Right" },
  { mobile: "/Swiped Right.png", title: "Swiped Right" },
];

export const mobileScreen = [
  { mobile: "/Home Page 1.png" },
  { mobile: "/Swap Home 1.png" },
  { mobile: "/Listing (Address) 1.png" },
  { mobile: "/Listing (Define your property) 1.png" },
  { mobile: "/gallery.png" },
  { mobile: "/Listing (Add Photos , open camera) 1.png" },
  { mobile: "/Listing (Add Photos , open camera) (1) 1.png" },
  { mobile: "/Listing (Amenities) 1.png" },
  { mobile: "/Describe your Listing 1.png" },
  { mobile: "/Security and Safety 1.png" },
  { mobile: "/House Rules 1.png" },
  { mobile: "/avaliable.png" },
  { mobile: "/Setup.png" },
  { mobile: "/Listing Complete 1.png" },
  { mobile: "Enter Destination 1.png" },
  { mobile: "/Homes 1.png" },
  { mobile: "/Home 1 1.png" },
  { mobile: "/Request Sent 1.png" },
  { mobile: "/16.PNG" },
  { mobile: "/17.PNG" },
  { mobile: "/18.PNG" },
  { mobile: "/19.PNG" },
  { mobile: "/20.PNG" },
  { mobile: "/21.PNG" },
  { mobile: "/22.PNG" },
  { mobile: "/23.PNG" },
  { mobile: "/24.PNG" },
  { mobile: "/25.PNG" },
  { mobile: "/26.PNG" },
  { mobile: "/27.PNG" },
  { mobile: "/28.PNG" },
  { mobile: "/29.PNG" },
  { mobile: "/29.PNG" },
  { mobile: "/31.PNG" },
  { mobile: "/34.PNG" },
  { mobile: "/33.PNG" },
];
export const fitnessmobileScreen = [
  { mobile: "/Lock Screen 2.png" },
  { mobile: "/Open App 3.png" },
  { mobile: "/Sign In 2.png" },
  { mobile: "/Notification 3.png" },
  { mobile: "/notifi.png" },
  { mobile: "/Connection Accepted 1.png" },
  { mobile: "/m100.PNG" },
  { mobile: "/m27.PNG" },
  { mobile: "/m81.PNG" },
  { mobile: "/m29.PNG" },
  { mobile: "/m30.PNG" },
  { mobile: "/m91.PNG" },
];

export const mobilshots = [
  { mobile: "/Swap Home 1.png" },
  { mobile: "/My Listing (Profile Setup) (1) 1.png" },
  { mobile: "/Enter Destination 2.png" },
  { mobile: "/Homes 2.png" },
  { mobile: "/Home 2 1.png" },
  { mobile: "/Request Sent 2.png" },
  // { mobile: "/Listing (Add Photos , open camera) (1) 1.png" },
  // { mobile: "/Listing (Amenities) 1.png" },
  // { mobile: "/Describe your Listing 1.png" },
  // { mobile: "/Security and Safety 1.png" },
  // { mobile: "/House Rules 1.png" },
  // { mobile: "/avaliable.png" },
  { mobile: "/35.PNG" },
  { mobile: "/36.PNG" },
  { mobile: "/37.PNG" },
  { mobile: "/38.PNG" },
  { mobile: "/39.PNG" },
  { mobile: "/40.PNG" },
];
export const fitnessmobilshots = [
  { mobile: "/Sign In 3.png" },
  { mobile: "Home Page 3.png" },
  { mobile: "/Apple Watch ( Home page) 1.png" },
  { mobile: "/Exercise page (Cycling selected) 1.png" },
  { mobile: "/Exercise page (Start Cycling) 1.png" },
  { mobile: "/Exercise page (Cycling Map) 1.png" },
  { mobile: "/Apple Watch ( Cycling count down 1) 1.png" },
  { mobile: "/Apple Watch ( Cycling Count down 2) 1.png" },
  { mobile: "/Apple Watch ( Cycling count down 3) 1.png" },
  { mobile: "/Exercise page (Countdown 3) 1.png" },
  { mobile: "/Exercise page (Countdown 2) 1.png" },
  { mobile: "/Exercise page (Countdown 1) 1.png" },
  { mobile: "/Exercise page (Cycling in progress) 1 (1).png" },
  { mobile: "/Apple Watch ( Cycling in progress) 1.png" },
  { mobile: "/Apple Watch ( Cycling paused) 1.png" },
  { mobile: "/Exercise page (Cycling complete) 1.png" },
  { mobile: "/Apple Watch ( Cycling goal reached) 1.png" },
  { mobile: "/cycle1.png" },
  { mobile: "/m28.PNG" },
  { mobile: "/m82.PNG" },
  { mobile: "/56CCQT1 1.png" },
  { mobile: "/m89.PNG" },
  { mobile: "/m83.PNG" },
  { mobile: "/m84.PNG" },
  { mobile: "/m85.PNG" },
  { mobile: "/m86.PNG" },
  { mobile: "/m87.PNG" },
  { mobile: "/46CCQT1 1.png" },
  { mobile: "/36CCQT1 1.png" },
  { mobile: "/26CCQT1 1.png" },
  { mobile: "/m32.PNG" },
  { mobile: "/16CCQT1 1.png" },
  { mobile: "/O6CCQT1 1 (1).png" },
  { mobile: "/m91.PNG" },
  { mobile: "/ssss.png" },
  { mobile: "/m90.PNG" },
];
export const homescreenshotspages = [
  { mobile: "/other.png" },
  { mobile: "/other 1.png" },
  { mobile: "/other 2.png" },
  { mobile: "/other 3.png" },
  { mobile: "/other1.PNG" },
  { mobile: "/other2.PNG" },
  { mobile: "/other3.PNG" },
  { mobile: "/other4.PNG" },
];
export const screenshots = [
  { mobile: "/Home Page 2.png" },
  { mobile: "/Find Homes 1.png" },
  { mobile: "/Search Home 1.png" },
  { mobile: "/Africa (Nigeria) 1.png" },
  { mobile: "/Africa (Seychelles) 1.png" },
  { mobile: "/Africa (Namibia) 1.png" },
  { mobile: "/Europe (France) 1.png" },
  { mobile: "/Europe (Italy) 1.png" },
  { mobile: "/Europe (Denmark) 1.png" },
  { mobile: "/Homes 3.png" },
  { mobile: "/Home 3 1.png" },
  { mobile: "/Request Sent 3.png" },
  { mobile: "/41.PNG" },
  { mobile: "/42.PNG" },
  { mobile: "/43.PNG" },
  { mobile: "/44.PNG" },
  { mobile: "/45.PNG" },
  { mobile: "/46.PNG" },
  { mobile: "/47.PNG" },
  { mobile: "/48.PNG" },
  { mobile: "/49.PNG" },
  { mobile: "/50.PNG" },
  { mobile: "/51.PNG" },
  { mobile: "/52.PNG" },
];
export const fitnessscreenshots = [
  { mobile: "/Home Page 4.png" },
  { mobile: "/Diet Planner 1.png" },
  { mobile: "/Diet Planner ( Meal Tracked) 1.png" },
  { mobile: "/Diet Planner (progress tracker) 1.png" },
  { mobile: "/m96.PNG" },
  { mobile: "/m95.PNG" },
  { mobile: "/m94.PNG" },
  { mobile: "/m93.PNG" },
];
export const screenshotspages = [
  { mobile: "/Find Homes 2.png" },
  { mobile: "/Listing (Address) 2.png" },
  { mobile: "/Listing (Define your property) 2.png" },
  { mobile: "/Listing (Add Photos , open camera) 2.png" },
  { mobile: "/Listing (Add).png" },
  { mobile: "/Listing (Add Photos , open camera) (1) 2.png" },
  { mobile: "/Listing (Amenities) 2.png" },
  { mobile: "/Describe your Listing 2.png" },
  { mobile: "/Security and Safety 2.png" },
  { mobile: "/House Rules 2.png" },
  { mobile: "/Pricing 1.png" },
  { mobile: "/When is.png" },
  { mobile: "/56.PNG" },
  { mobile: "/55.PNG" },
  { mobile: "/57.PNG" },
  { mobile: "/58.PNG" },
  { mobile: "/58.PNG" },
  { mobile: "/59.PNG" },
  { mobile: "/60.PNG" },
  { mobile: "/61.PNG" },
  { mobile: "/62.PNG" },
  { mobile: "/63.PNG" },

  { mobile: "/64.PNG" },
  { mobile: "/65.PNG" },
];
export const fitnessscreenshotspages = [
  { mobile: "/Lock Screen 2.png" },
  { mobile: "/Open App 3.png" },
  { mobile: "/Sign In 4.png" },
  { mobile: "/Notification 4.png" },
  { mobile: "/Buddy Screen 1.png" },
  { mobile: "/s2.PNG" },
  { mobile: "/s3.PNG" },
  { mobile: "/s4.PNG" },
  { mobile: "/s5.PNG" },
  { mobile: "/s6.PNG" },
];
export const icon = [
  "/Payment Transaction.png",
  "/Icon-Arrow-Left.png",
  "/Reading.png",
  "/Back arrow.png",
  "/Save.png",
  "/downloade.png",
  "/Trash.png",
  "/Vector (1).png",
  "/25-Hot cooking.png",
  "/music.png",
  "/Piano.png",
  "/Gardening.png",
  "/Star 7.png",
  "/Vector (2).png",
  "/Guitar.png",
  "/Upload icon.png",
  "/Video.png",
  "/Edit.png",
  "/History.png",
  "/search.png",
  "/Game.png",
  "/like.png",
  "/Flight.png",
  "/Tv.png",
  "/Apple.png",
  "/calendar_1_.png",
  "/Camera.png",
  "/Facebook.png",
  "/Toggle button.png",
  "/Dancing.png",
  "/formIcons.png",
  "/Settings.png",
  "/Person.png",
  "/Filter.png",
  "/Swimming.png",
  // "/Dancing.png",
  "/Notification.png",
  "/location.png",
  "/Google.png",
  "/Listing.png",
  "/Brush.png",
  "/Warnign.png",
  "/Soccer.png",
  "/Chat.png",
];
