import React from "react";

const mapOne = () => {
  return (
    <React.Fragment>
      <div className=" max-w-[1040px] w-[100%] m-auto sm:px-0 px-6">
        <p className="sm:text-[24px] text-[20px] sm:mt-4 mt-7 font-[700]">
          Ideate:
        </p>
        <p className="font-[600] text-[18px] sm:text-[22px] mt-[8px]">Personas:</p>
      </div>
      <div className="mt-[2px] max-w-[1040px] w-[100%] m-auto sm:px-5 px-6 bg-[#fff9f8]">
        <div className="grid grid-cols-12 sm:space-x-7 space-x-2 ">
          <div className="sm:col-span-4 col-span-12 mt-4 ">
            <div className="bg-[#EB8A73] p-3 rounded-lg w-fit">
              <img className="rounded-lg" src="/caroline.png" alt="img " />
            </div>
          </div>
          <div className="sm:col-span-8 col-span-12 mt-4 w-full">
            <p className="text-[#B3B3B3]">Persona 1: Jake Sully (Home Owner)</p>
            <p className="text-black font-bold ">Profile :</p>
            <div className=" flex  items-center">
              <p className="w-32 sm:text-[16px] text-[14px]">Age :</p>
              <p className="sm:w-[450px] w-[250px] sm:text-[16px] text-[14px]">
                40Yrs
              </p>
            </div>
            <div className=" flex  items-center">
              <p className="w-32 sm:text-[16px] text-[14px]">Status :</p>
              <p className=" sm:text-[16px] text-[14px] sm:w-[450px] w-[250px]">
                Married with kds
              </p>
            </div>
            <div className=" flex  items-center">
              <p className="w-32 sm:text-[16px] text-[14px]">Occupation :</p>
              <p className="sm:w-[450px] w-[250px] sm:text-[16px] text-[14px]">
                Computer Technician
              </p>
            </div>
            <div className=" flex  items-center">
              <p className="w-32 sm:text-[16px] text-[14px]">Location :</p>
              <p className="sm:w-[450px] w-[250px] sm:text-[16px] text-[14px]">
                Hong Kong (Sha Tin)
              </p>
            </div>
            <div className=" flex  items-center">
              <p className="w-32">Strength :</p>
              <p className="sm:w-[450px] w-[250px] sm:text-[16px] text-[14px]">
                Friendly, Organized and ambitious
              </p>
            </div>
            <div className=" flex  items-center">
              <p className="w-32 sm:text-[16px] text-[14px]">Weakness:</p>
              <p className="sm:w-[450px] w-[250px]  sm:text-[16px] text-[14px]">
                Works too much, Easily bored and Takes on too much
              </p>
            </div>
            <div className=" flex  items-center">
              <p className="w-32 sm:text-[16px] text-[14px]">Device (s):</p>
              <p className="sm:w-[450px] w-[250px] sm:text-[16px] text-[14px]">
                Iphone, PC
              </p>
            </div>
          </div>
        </div>
        <p className="sm:text-[24px] text-[16px] mt-10 font-[400]">
          Bio :
          <p className="sm:text-[18px] text-[14px] mt-3 font-[400]">
            Jake Sully is a family man who lives in Hong Kong with his stay at
            home wife and three kids. He owns and runs a computer repair store
            in the heart of Sha Tin city and makes a net income of about $40,000
            per year. Due to the traffic congestion on his route to and from
            work, he spends about 20 hours per week on commute. Jake leaves home
            6am everyday and gets back around 10pm. As a family man, this gives
            him very little time to spend with his lovely family and because of
            this, he enjoys going on vacation about 2 to 3 times a year with his
            family but due to the increasing hike in the cost of vacation
            expenses, jake is looking for a more cost effective way within his
            budget to enjoy the convenience and warmth of a home away from home
            with his family while on vacation.
          </p>
        </p>
        <p className="sm:text-[22px] text-[16px] mt-5 font-[400]">
          Goal :
          <ul className="list-disc ml-5 mt-2 font-[400] sm:text-[18px] text-[14px]">
            <li>Find a more cost effective way to vacation </li>
            <li>Spend time with family</li>
            <li>
              Find a place that gives the feel of a home away from home
              (Convenience and warmth){" "}
            </li>
            <li>A space with a garden for his kids to play.</li>
          </ul>
        </p>
        <p className="sm:text-[22px] text-[16px] mt-5 font-[400]">
          Pain Points:
          <ul className="list-disc ml-5 mt-2 font-[400] sm:text-[18px] text-[14px]">
            <li>
              Not enough company offering the services of exchange/swap home for
              short lent
            </li>
            <li>
              Websites of the few company that offers similar services are
              cumbersome and not user centric, hence, difficult to
              <br /> navigate through
            </li>
            <li>Available platforms that offer this service, cost too much.</li>
          </ul>
        </p>
        <div className="lg:w-2/4 md:w-3/4 w-full">
          <p className="sm:text-[22px] text-[16px] mt-5 font-[400]">
            Interest :
          </p>
          <div className="flex items-center">
            <p className="w-[200px] text-[18px] ">Family </p>
            <div class="w-full bg-[#b1bac7] rounded-full h-4">
              <div
                class="bg-[#415C7C] h-4 rounded-full"
                style={{ width: "85%" }}
              ></div>
            </div>
          </div>
          <div className="flex items-center">
            <p className="w-[200px] text-[18px] ">Entertainment </p>
            <div class="w-full bg-[#b1bac7] rounded-full h-4">
              <div
                class="bg-[#415C7C] h-4 rounded-full"
                style={{ width: "45%" }}
              ></div>
            </div>
          </div>
          <div className="flex items-center">
            <p className="w-[200px] text-[18px] ">Technology </p>
            <div class="w-full bg-[#b1bac7] rounded-full h-4">
              <div
                class="bg-[#415C7C] h-4 rounded-full"
                style={{ width: "55%" }}
              ></div>
            </div>
          </div>
          <div className="flex items-center">
            <p className="w-[200px] text-[18px] ">Game</p>
            <div class="w-full bg-[#b1bac7] rounded-full h-4">
              <div
                class="bg-[#415C7C] h-4 rounded-full"
                style={{ width: "35%" }}
              ></div>
            </div>
          </div>
          <div className="flex items-center">
            <p className="w-[200px] text-[18px] ">Music</p>
            <div class="w-full bg-[#b1bac7] rounded-full h-4">
              <div
                class="bg-[#415C7C] h-4 rounded-full"
                style={{ width: "10%" }}
              ></div>
            </div>
          </div>
        </div>
        <div className="lg:w-2/4 md:w-3/4 w-full">
          <p className="sm:text-[22px] text-[16px] mt-5 font-[400]">
            Personality:
          </p>
          <div className="grid grid-cols-5 gap-2 items-center md:w-[500px] ml-10">
            <p className="sm:text-[18px] text-[14px] text-right">Introvert </p>
            <div class="col-span-3 w-full max-w-[300px] bg-[#b1bac7] rounded-full h-4 bg-gradient-to-r from-cyan-200 to-red-400">
              <div
                class="bg-[#415C7C] h-4 rounded-full  ml-[65%]"
                style={{ width: "20%" }}
              ></div>
            </div>
            <p className="sm:text-[18px] text-[14px] text-left">Extrovert</p>
            
            <p className="sm:text-[18px] text-[14px] ml-3 text-right">Passive </p>
            <div class="col-span-3 w-full max-w-[300px] bg-[#b1bac7] rounded-full h-4  bg-gradient-to-r from-cyan-200 to-red-400">
              <div
                class="bg-[#415C7C] h-4 rounded-full ml-[50%]"
                style={{ width: "20%"}}
              ></div>
            </div>
            <p className="w-2/4 sm:text-[18px] text-[14px] ">Active </p>
          </div>
        </div>
        <p className="sm:text-[18px] text-[14px] mt-10 font-[400]">
          From interviews carried out for this target group, i was able to
          deduct and understand their behavior and lifestyle and <br />
          created one persona from this group to sum them up. From my research
          data, to further expasiate on what this users
          <br />
          <span className="font-bold">said, did, thought</span> and{" "}
          <span className="font-bold">felt,</span> i used an{" "}
          <span className="font-bold">empathy map.</span>
        </p>
        <div className="border-[1px] relative mt-10 border-red-500 rounded-[50px] sm:text-[18px] text-[14px] font-[400]">
          <div className="  grid grid-cols-12  ">
            <div className="col-span-6 border-red-500 border-r  border-b">
              <div className=" items-center py-10 homeswap-1">
                <div className="sm:px-10 px-5">
                  <p className=" text-center justify-center font-[700] sm:text-[22px] text-[17px]">
                    Said:
                  </p>
                  <ul className="list-disc px-[3px]">
                    <li>
                      I am worried i am not spending enough time with my family
                      In the past i usually go on vacation with my family 2 to 3
                      times per year
                    </li>
                    <li>
                      The hike in cost of travel and accommo dation has affected
                      my family vacation greatly.
                    </li>
                    <li>
                      I want to still go on vacation with my family and so i
                      need a more cost efficient means to do so.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-span-6 border-red-500  border-b ">
              <div className="justify-center items-center py-10">
                <div className="sm:px-10 px-5 homeswap-1">
                  <p className="font-[700] sm:text-[22px] text-[17px]">Did</p>
                  <ul className="list-disc px-[3px] mt-2 ">
                    <li>
                      Tried to leave work early one or twice a week to spend
                      time with his kids .
                    </li>
                    <li>
                      Searched the internet for an affordable way to travel with
                      his family
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className=" col-span-6 border-red-500 border-r">
              <div className=" py-10 ">
                <div className="sm:px-10 px-5 homeswap-2">
                  <p className=" text-center justify-center items-center font-[700] sm:text-[22px] text-[17px]">
                    Thought:
                  </p>
                  <ul className="list-disc px-[3px]  mt-2">
                    <li>
                      Coming home early once or twice a week is not sustainable,
                      my family and i need a proper vacation to spend quality
                      time together.
                    </li>
                    <li>
                      I wish there was a platform where we can exchange homes
                      with other family for the period of our vacation. Taking
                      out accommo dation would massively reduce the financial
                      cost of our vacation.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-span-6 border-red-500 ">
              <div className="py-10">
                <div className="sm:px-10 px-5 homeswap-2">
                  <p className=" text-center justify-center font-[700] sm:text-[22px] text-[17px]">
                    Felt
                  </p>
                  <ul className="list-disc px-[3px]   mt-2">
                    <li>I feel like an absentee Dad</li>
                    <li>I am missing out on so much in my family</li>
                    <li>Vacation helps me bond with my family</li>
                    <li>
                      Frustrated with the results of his search for home
                      exchange services.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="absolute sm:left-[40.5%] sm:top-[38%] md:left-[43%] md:top-[38%]
          homeswap-3 pt-[30px] border-red-500 border rounded-full sm:h-[141px] h-[100px] sm:w-[141px] w-[100px]">
            <p className="text-[#EB8A73] text-center sm:text-[24px] text-[14px] font-[700]">
              {" "}
              User 1:
            </p>
            <p className=" sm:text-[22px] text-[14px] text-center font-[700]">
              {" "}
              Jake Sully{" "}
            </p>
          </div>
        </div>
        <p className="sm:text-[18px] text-[14px] my-20 sm:my-5 mb-5 mt-2 text-center sm:mt-10 mt-2 font-[400] text-[#B3B3B3]">
          Empathy Map 1
        </p>
      </div>
    </React.Fragment>
  );
};

export default mapOne;
