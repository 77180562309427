import React from "react";

const MapTwo = () => {
  return (
    <React.Fragment>
      <div className="max-w-[1040px] w-[100%] bg-[#f1f8ff] m-auto sm:px-5 px-6">
        <div className="grid grid-cols-12 sm:space-x-7 space-x-3 sm:mt-10  mt-0">
          <div className="sm:col-span-4 col-span-12  sm:mt-5 ">
            <div className="mt-3 bg-[#415C7C] w-fit p-3 rounded-lg">
              <img className="rounded-lg" src="/eric.png" alt="img " />
            </div>
          </div>
          <div className="sm:col-span-8 col-span-12 sm:mt-5 mt-1  w-full">
            <p className="text-[#B3B3B3]">
              Persona 2: Michael and June Adigun(Guest)
            </p>

            <div className=" flex  items-center">
              <p className="w-32 sm:text-[16px] text-[14px]">Age :</p>
              <p className="sm:w-[450px] w-[250px] sm:text-[16px] text-[14px]]">
                30 and 28Yrs{" "}
              </p>
            </div>
            <div className=" flex  items-center">
              <p className="w-32 sm:text-[16px] text-[14px]">Status :</p>
              <p className="sm:w-[450px] w-[250px] sm:text-[16px] text-[14px]">
                Married
              </p>
            </div>
            <div className=" flex  items-center">
              <p className="w-32 sm:text-[16px] text-[14px]">Occupation :</p>
              <p className="sm:w-[450px] w-[250px] sm:text-[16px] text-[14px]">
                {" "}
                Travel Bloggers
              </p>
            </div>
            <div className=" flex  items-center">
              <p className="w-32 sm:text-[16px] text-[14px]">Location :</p>
              <p className="sm:w-[450px] w-[250px] sm:text-[16px] text-[14px]">
                Ghana
              </p>
            </div>
            <div className=" flex  items-center">
              <p className="w-32 sm:text-[16px] text-[14px]">Strength :</p>
              <p className="sm:w-[450px] w-[250px] sm:text-[16px] text-[14px]">
                Friendly, Organized, Curious, Focused, Kind, Charming, Active
              </p>
            </div>
            <div className=" flex  ">
              <p className="w-32 sm:text-[16px] text-[14px]">Weakness:</p>
              <p className="sm:w-[450px] w-[250px] sm:text-[16px] text-[14px]">
                Persistent, Impulsive, Likes to take Risks, Takes on too much,
                Perfectionist
              </p>
            </div>
            <div className=" flex  items-center">
              <p className="w-32 sm:text-[16px] text-[14px]">Device (s):</p>
              <p className="sm:w-[450px] w-[250px] sm:text-[16px] text-[14px]">
                iPhone, PC, iPad, Laptop
              </p>
            </div>
          </div>
        </div>
        <p className="sm:text-[24px] text-[16px] mt-10 font-[400]">
          Bio :
          <p className="sm:text-[18px] text-[14px] mt-3 font-[400]">
            Michael and June are both Travel bloggers who meet on the job and
            have been married for a little over a year. The job involves them
            traveling from cities to cities, countries to countries. They both
            love their jobs not only because it allows them travel to different
            countries, learn about different cultures, meet different people
            from around the globe and share those incredible moments with other
            people through their blogs, it also gives them a lot of time
            together. Lately, they have been getting tired of staying in hotel
            rooms since they are always away from home. They need a space that
            gives them a feel of home, while they are far away from home in
            other cities or countries.
          </p>
        </p>
        <p className="sm:text-[22px] text-[16px] mt-5 font-[400]">
          Goal :
          <ul className="list-disc ml-5 mt-2 font-[400] sm:text-[18px] text-[14px]">
            <li>
              Find a short lent space that is convenient for both living and
              working
            </li>
            <li>
              Find a place that gives the feel of a home away from home
              (Convenience and warmth).
            </li>
          </ul>
        </p>
        <p className="sm:text-[22px] text-[16px] mt-5 font-[400]">
          Pain Points:
          <ul className="list-disc ml-5 mt-2 font-[400] sm:text-[18px] text-[14px]">
            <li>
              Not enough company offering the services of home booking for short
              lent
            </li>
            <li>
              Websites of the few company that offers similar services are
              cumbersome and not user centric, hence, difficult to
              <br /> navigate through
            </li>
            <li>Available platforms that offer this service, cost too much.</li>
          </ul>
        </p>
        <div className="lg:w-2/4 md:w-3/4 w-full">
          <p className="sm:text-[22px] text-[16px] mt-5 font-[400]">
            Interest :
          </p>
          <div className="flex items-center font-[400] sm:text-[18px] text-[14px]">
            <p className="w-[200px] ">Family </p>
            <div class="w-full bg-[#b1bac7] rounded-full h-4">
              <div
                class="bg-[#415C7C] h-4 rounded-full"
                style={{ width: "85%" }}
              ></div>
            </div>
          </div>
          <div className="flex items-center font-[400] sm:text-[18px] text-[14px]">
            <p className="w-[200px] ">Entertainment </p>
            <div class="w-full bg-[#b1bac7] rounded-full h-4">
              <div
                class="bg-[#415C7C] h-4 rounded-full"
                style={{ width: "25%" }}
              ></div>
            </div>
          </div>
          <div className="flex items-center font-[400] sm:text-[18px] text-[14px]">
            <p className="w-[200px] ">Technology </p>
            <div class="w-full bg-[#b1bac7] rounded-full h-4">
              <div
                class="bg-[#415C7C] h-4 rounded-full"
                style={{ width: "90%" }}
              ></div>
            </div>
          </div>
          <div className="flex items-center font-[400] sm:text-[18px] text-[14px]">
            <p className="w-[200px]  ">Game</p>
            <div class="w-full bg-[#b1bac7] rounded-full h-4">
              <div
                class="bg-[#415C7C] h-4 rounded-full"
                style={{ width: "75%" }}
              ></div>
            </div>
          </div>
          <div className="flex items-center font-[400] sm:text-[18px] text-[14px]">
            <p className="w-[200px]  ">Music</p>
            <div class="w-full bg-[#b1bac7] rounded-full h-4">
              <div
                class="bg-[#415C7C] h-4 rounded-full"
                style={{ width: "15%" }}
              ></div>
            </div>
          </div>
        </div>
        <div className="lg:w-2/4 md:w-3/4 w-full">
          <p className="sm:text-[22px] text-[16px] mt-5 font-[400]">
            Personality:
          </p>
          <div className="grid grid-cols-5 gap-2 items-center md:w-[500px] ml-6 sm:ml-10">
            <p className="font-[400] sm:text-[18px] text-[14px] text-right min-w-[10px]">Introvert </p>
            <div class="col-span-3 w-full max-w-[300px] bg-[#b1bac7] rounded-full h-4 bg-gradient-to-r from-cyan-200 to-red-400">
              <div
                class="bg-[#415C7C] h-4 rounded-full  ml-[45%]"
                style={{ width: "20%" }}
              ></div>
            </div>
            <p className="font-[400] sm:text-[18px] text-[14px] text-left">Extrovert</p>
            
            <p className="font-[400] sm:text-[18px] text-[14px] ml-3 text-right">Passive </p>
            <div class="col-span-3 w-full max-w-[300px] bg-[#b1bac7] rounded-full h-4  bg-gradient-to-r from-cyan-200 to-red-400">
              <div
                class="bg-[#415C7C] h-4 rounded-full ml-[60%]"
                style={{ width: "20%"}}
              ></div>
            </div>
            <p className="w-2/4font-[400] sm:text-[18px] text-[14px] ">Active </p>
          </div>
        </div>
        <p className="sm:text-[18px] text-[14px] mt-10 font-[400]">
          From interviews carried out for this target group, i was able to
          deduct and understand their behavior and lifestyle and <br />
          created one persona from this group to sum them up. From my research
          data, to further expasiate on what this users
          <br />
          <span className="font-bold">said, did, thought</span> and{" "}
          <span className="font-bold">felt,</span> i used an{" "}
          <span className="font-bold">empathy map.</span>
        </p>
        <div className="border-[1px] relative mt-10 sm:border-red-500 border-[#415C7C] rounded-[50px] sm:text-[18px] text-[14px] font-[400]">
          <div className="  grid grid-cols-12  ">
            <div className="col-span-6 sm:border-r-red-500 border-r border-b border-r-[#415C7C] sm:border-b-red-500 border-b-[#415C7C]">
              <div className=" items-center py-10 swap-home">
                <div className="sm:px-10 px-5">
                  <p className=" text-center justify-center font-[700] sm:text-[22px] text-[17px]">
                    Said:
                  </p>
                  <ul className="list-disc px-[3px]">
                    <li>My partner and i enjoy working together.</li>
                    <li>
                      We love to explore and experience different culture from
                      around the world.
                    </li>
                    <li>
                      We are tired of staying in hotel rooms during our trips.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-span-6 sm:border-b-red-500 border-b-[#415C7C]  border-b ">
              <div className="justify-center items-center py-10">
                <div className="sm:px-10 px-5 swap-home">
                  <p className="text-center justify-center font-[700] sm:text-[22px] text-[17px]">Did</p>
                  <ul className="list-disc  mt-2 px-[3px]">
                    <li>Tried to cut down on their travels.</li>
                    <li>
                      Searched the internet for companies who offer short lent
                      homes services.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className=" col-span-6 sm:border-r-red-500 border-r border-r-[#415C7C]">
              <div className=" py-10 ">
                <div className="sm:px-10 px-5 swap-home1">
                  <p className=" text-center justify-center font-[700] sm:text-[22px] text-[17px]">
                    Thought:
                  </p>
                  <ul className="list-disc  mt-2 ">
                    <li>
                      We need to find the balance between our work lives and our
                      marriage.
                    </li>
                    <li>
                      Although we travel a lot together because of our blog, we
                      would still love and want to have the conventional life of
                      a married couple.
                    </li>
                    <li>We feel more like work colleagues than a couple.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-span-6 border-red-500 ">
              <div className="py-10">
                <div className="sm:px-10 px-5 swap-home1">
                  <p className="text-center justify-center font-[700] sm:text-[22px] text-[17px]">
                    Felt
                  </p>
                  <ul className="list-disc  mt-2">
                    <li>
                      A home away from home would help us feel connected to each
                      other and also not lose ourselves to our jobs.{" "}
                    </li>
                    <li>
                      {" "}
                      Long stays in hotels eventually gets boring and we begin
                      to feel clustered.
                    </li>
                    <li>Vacation helps me bond with my family</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="absolute sm:left-[43%] sm:top-[31%] swap-circle-1 pt-[30px] border-[#415C7C]  sm:border-red-500 border rounded-full
           sm:h-[141px] h-[100px] sm:w-[141px] w-[100px]">
            <p className="sm:text-[#EB8A73] text-[#415C7C] text-center sm:text-[24px] text-[14px] font-[700]">
              {" "}
              User 2:
            </p>
            <p className=" sm:text-[20px] text-[14px] text-center font-[700]">
              {" "}
              Michael & June
            </p>
          </div>
        </div>
        <p className="sm:text-[18px] text-[14px] sm:my-4 my-2 text-center mt-2 font-[400] text-[#B3B3B3]">
          Empathy Map 2
        </p>
      </div>
    </React.Fragment>
  );
};

export default MapTwo;
