import React from "react";
import Footerbotton from "../../components/footerBottom";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Link } from "react-router-dom";
import Problem from "../../components/problems";
const Fitness = () => {
  return (
    <React.Fragment>
      <Header />
      <div className="sm:pt-24 pt-20">
        <div
          style={{
            background:
              "linear-gradient(90.07deg, #3B5C59 0.07%, #275C74 79.65%, #556664 99.94%)",
          }}
        >
          <div className="container m-auto">
            <div className="grid relative grid-cols-12   justify-center items-center py-10 ">
              <div className="sm:col-span-6 col-span-12">
                <div className="flex justify-center w-full">
                  <img
                    // src="/coen.png"
                    src="bycycle.png"
                    alt="imgs"
                    className=" opacity-[0.7]  sm:h-auto h-[250px] w-[300px]"
                  />
                </div>
              </div>
              <div className="sm:col-span-6 w-full sm:relative absolute">
                <h1 className="font-medium text-white sm:text-[40px] text-[20px] sm:text-left text-center">
                  Fitness Buddies
                </h1>
                <p className="sm:text-2xl text-[14px] sm:text-left text-center font-[300] text-white">
                  UI/UX Design Case study
                </p>
                <div className="mt-10 sm:w-full w-[200px] m-auto  ">
                  <Link
                    onClick={() =>
                      window.open(
                        "https://www.figma.com/file/qdET27Le4AfXwnQRCUN8v0/Fitness-Buddies-design?type=design&node-id=243%3A7399&t=BIrUNFo1v4qzgMRY-1",
                        "_blank"
                      )
                    }
                    className="bg-[#59B2A9] font-bold mt-[550px]  border-2-[#E88C77] text-white w-[200px] m-auto  rounded-lg py-4 px-7 ml-[19px]"
                  >
                    View Prototype
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Problem />
      <div className="mt-3">
        <Footer />
      </div>

      <Footerbotton />
    </React.Fragment>
  );
};

export default Fitness;
