import React from "react";

const Personas = () => {
  return (
    <React.Fragment>
      <div className=" sm:bg-white sm:px-5 px-3">
        <div className=" max-w-[1040px] w-[100%] m-auto sm:px-5 px-3  bg-[#f9fff6]">
          {/* <p className="sm:text-[26px] text-[16px] mt-10 font-[700]">
            Persona 1:
          </p> */}
          <div className="grid grid-cols-12 sm:space-x-12 space-x-2 ">
            <div className="sm:col-span-4 col-span-12 mt-4 ">
              <div className="bg-[#59B2A9] w-fit p-3 rounded-lg">
                <img className="rounded-lg" src="/samuel.png" alt="img " />
              </div>
            </div>
            <div className="sm:col-span-8 col-span-12 mt-4 w-full">
              <p className="text-[#B3B3B3] sm:text-[22px] text-[20px] font-[700]">
                <a className="font-bold text-zinc-900">Persona 1:</a> Caleb Winter
              </p>
              <p className="text-black font-bold ">Profile :</p>
              <div className=" flex  items-center">
                <p className="w-32 sm:text-[16px] text-[14px]">Age :</p>
                <p className="sm:w-[450px] w-[250px] text-[14px]">21yrs </p>
              </div>
              <div className=" flex  items-center">
                <p className="w-32 sm:text-[16px] text-[14px]">Status :</p>
                <p className="sm:w-[450px] w-[250px] sm:text-[16px] text-[14px]">
                  Single{" "}
                </p>
              </div>
              <div className=" flex  items-center">
                <p className="w-32 sm:text-[16px] text-[14px]">Occupation :</p>
                <p className="sm:w-[450px] w-[250px] sm:text-[16px] text-[14px]">
                  Student
                </p>
              </div>
              <div className=" flex  items-center">
                <p className="w-32 sm:text-[16px] text-[14px]">Location :</p>
                <p className="sm:w-[450px] w-[250px] sm:text-[16px] text-[14px]">
                  {" "}
                  Utrecht
                </p>
              </div>
              <div className=" flex  items-center">
                <p className="w-32 sm:text-[16px] text-[14px]">Device Type :</p>
                <p className="sm:w-[450px] w-[250px] sm:text-[16px] text-[14px]">
                  Iphone
                </p>
              </div>
              <div className=" flex  items-center">
                <p className="w-32 sm:text-[16px] text-[14px]">Goal(s):</p>
                <p className="sm:w-[450px] w-[250px] sm:text-[16px] text-[14px]">
                  {" "}
                  Muscle gain{" "}
                </p>
              </div>
              <div className=" flex  items-center">
                <p className="w-32 sm:text-[16px] text-[14px]">Strength :</p>
                <p className="sm:w-[450px] w-[250px] sm:text-[16px] text-[14px]">
                  Lots of free time, Social{" "}
                </p>
              </div>
              <div className=" flex  items-center">
                <p className="w-32 sm:text-[16px] text-[14px]">Weakness:</p>
                <p className="sm:w-[450px] w-[250px] sm:text-[16px] text-[14px]">
                  Short term interest, Lack discipline and Limited funds
                </p>
              </div>
            </div>
          </div>

          <p className="sm:text-[22px] text-[16px] mt-5 font-[600]">
            Behaviours:
          </p>
          <ol className="sm:text-[16px] text-[14px] mt-2">
            <p>i) Has lectures only on mondays and tuesdays every week </p>
            <p>ii) Has alot of freetime to spare </p>
            <p>iii) Enjoy eating out (Junk foods) with friends </p>
            <p>
              iv) He cares about his body image and would get more confidence if
              he is fit{" "}
            </p>
            <p>v) Has limited funds to spend on fitness services</p>
            <p>vi) Short term interest in things</p>
            <p>
              {" "}
              vii) Gets high energy when he worksout in his dorm room with his
              friends
            </p>
          </ol>

          <p className="sm:text-[16px] text-[14px] mt-10 font-[400]">
            From interviews carried out for this target group, i was able to
            deduct and understand their behavior and lifestyle and <br />
            created one persona from this group to sum them up. From my research
            data, to further expasiate on what this users
            <br />
            <span className="font-bold">said, did, thought</span> and{" "}
            <span className="font-bold">felt,</span> i used an{" "}
            <span className="font-bold">empathy map.</span>
          </p>
          <div className="border-[1px] mt-10 border-[#59B2A9] rounded-[50px]">
            <div className=" relative grid grid-cols-12  ">
              <div className="col-span-6 border-[#59B2A9] border-r  border-b">
                <div className=" items-center py-10">
                  <div className="sm:px-10 px-5 sm:h-auto my-box1">
                    <p className=" text-center justify-center font-[700] text-[22px] ">
                      Said:
                    </p>
                    <ol className="   font-[400] sm:text-[18px] text-[12px]">
                      <p>
                        i) I have alot of freetime, i can want to be productive
                        with
                        <br />
                        my time
                      </p>
                      <p>
                        ii) I eat alot of junks (Fast foods) with my friends and
                        i want to do better
                      </p>
                      <p>
                        iii) I am conscious of my body and it does not give me a
                        lot of confidence.
                      </p>
                      <p>
                        iv) I cannot subscribe to a gym or employ the services
                        of a personal trainer because I cannot affort it.
                      </p>
                      <p>v) Some extrenal push would keeping me interested</p>
                      <p>vi) I want to be accountable to someone</p>
                    </ol>
                  </div>
                </div>
              </div>
              <div className="col-span-6 border-[#59B2A9]  border-b ">
                <div className="py-10">
                  <div className="sm:px-10 px-5 sm:h-auto my-box1">
                    <p className="font-[700] text-center  text-[22px]">Did</p>
                    <ol className="  font-[400] sm:text-[18px] text-[12px]">
                      <p>
                        i) Started running every Saturday mornings for 2 hours
                        but stopped after awhile
                      </p>
                      <p>
                        ii) Followed some online diet planning routine but
                        couldn’t keep up
                      </p>
                      <p>
                        iii) Started a gym subscription but couldn’t sustain it
                      </p>
                    </ol>
                  </div>
                </div>
              </div>
              <div className=" col-span-6 border-[#59B2A9] border-r">
                <div className=" py-10 ">
                  <div className="sm:px-10 px-5 sm:h-auto my-box11">
                    <p className=" text-center justify-center items-center font-[700] text-[22px]">
                      Thought:
                    </p>
                    <ol className="   font-[400] sm:text-[18px] text-[12px]">
                      <p>
                        i) I am a student, eating healthy is way above my budget
                      </p>
                      <p>
                        ii) I don’t see any progress, might just be wasting my
                        time
                      </p>
                      <p>
                        iii) I am young, i will worry about my health when i am
                        older
                      </p>
                      <p>
                        iv) I wish i can someone who constantly check on my
                        progress
                      </p>
                      <p>
                        v) Today is my cheat day, so i will eat all the burgers
                        i can
                      </p>
                      <p>
                        vi) I will skip workout today and compensate for it next
                        week
                      </p>
                    </ol>
                  </div>
                </div>
              </div>
              <div className="col-span-6 border-[#59B2A9] ">
                <div className="py-10">
                  <div className="sm:px-10 px-5 sm:h-auto my-box11">
                    <p className=" text-center justify-center font-[700] text-[22px]">
                      Felt
                    </p>
                    <ol className="   font-[400] sm:text-[18px] text-[12px] mt-4">
                      <p>i) Lack of motivation</p>
                      <p>ii) Short lived interest</p>
                      <p>iii) Little or no progress seen</p>
                      <p>iv) Fitness apps are so unrealistic</p>
                      <p>
                        v) I walk to most places i go to, that counts, i am
                        good.
                      </p>
                      <p>vi) Healthy foods are so expensive</p>
                      <p>vii) Lack of confidence and low self esteem</p>
                    </ol>
                  </div>
                </div>
              </div>
              <div className="absolute lg:left-[43%] lg:top-[43%] md:left-[43%] md:top-[46%]  sm:left-[38%] sm:top-[49%] my-boxes
               sm:pt-[12px] md:pt-[20px] border-[#59B2A9] border-2 rounded-full sm:h-[141px] sm:w-[141px] h-[100px] w-[100px] p-2">
                <p className="text-[#59B2A9] text-center sm:text-[24px] text-[18px] font-[700]">
                  {" "}
                  User 1:
                </p>
                <p className=" sm:text-[22px] text-[16px] text-center font-[700]">
                  {" "}
                  Caleb Winter
                </p>
              </div>
            </div>
          </div>
          <p className="text-[18px] sm:my-5 mb-5 mt-1 text-center  font-[400] text-[#B3B3B3]">
            Empathy Map 1
          </p>
          <p className="sm:text-[22px] text-[16px] mt-5 font-[600]">
          Caleb’s Needs:
          </p>
          <ol className="   font-[400] sm:text-[18px] text-[14px] mt-4">
            <p>i) A diet planning routine as an alternative to junk foods </p>
            <p>ii) A personalized fitness routine </p>
            <p>iii) A healthier lifestyle </p>
            <p>
              iv) Someone to motivate and push him to reach his set goals
              because of his short termed interest in things
            </p>
          </ol>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Personas;
