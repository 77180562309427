import React from 'react';
import {
    prototypemobile,
  } from "../../data";

const Prototype = () => {
    return (
        <div>
          <div
              className=" flex justify-center "
              style={{ flexFlow: "wrap", width: "100%" }}
            >
              {prototypemobile.map((item, index) => (
                <div className="sm:w-[160px] w-[164px] sm:h-[310px] h-[310px] flex justify-center items-center">
                  <img src={item.mobile} alt="graph" className="p-2" />
                </div>
              ))}
            </div>
       
         {/* <div className="grid grid-cols-12 mt-10 px-7 sm:px-20">
        {prototypemobile.map((item, index) => (
          <div className="col-span-2 mt-1 h-[100%]">
            <img src={item.mobile} alt="name" />
          </div>
        ))}
      </div> */}
        </div>
    );
};

export default Prototype;