import React, { useState, useEffect } from 'react';
const Error = () => {

  let counter = 7;
  let textPrefix = "You will be redirected to homepage in ";
  let textSuffix = " seconds";
  const text = `You will be redirected to homepage in ${counter} seconds`;
  const [renderedText, setRenderedText] = useState(text);
  
  function updateText() {
    --counter
    if (counter < 0) {
      return;
    }
    if (counter === 1) {
      textSuffix = " second";
    }

    if (counter === 0) {
      setRenderedText("rediercting. . .");
    } else {
      setRenderedText(textPrefix + counter + textSuffix);
    }
  }
  useEffect(() => {

    const interval = setInterval(updateText, 1000)
    setTimeout(() => { window.location = "/"; }, 8400);
    return () => clearInterval(interval);
  }, []);

  return (
    <React.Fragment>
      <div style={{display: "flex", flexDirection: "column",
      minWidth: "250px", maxWidth: "500px",
      margin: "auto", justifyContent: "center", justifyItems: "center",
      }}>
      <p style={{ fontWeight: "bold", width: "auto", textAlign: "center", fontFamily: "'Courier New', Courier, monospace",
                  marginBottom: "20px", marginTop: "35px"}}>
        Sorry, this page is inexistent 😲
      </p>
      <p style={{ fontWeight: "lighter", width: "auto", textAlign: "center", fontFamily: "'Courier New', Courier, monospace"}}>
        {renderedText}
      </p>
    </div>
    </React.Fragment>
  );
};

export default Error;
