import React from "react";
import {
  fitnessmobile,
  fitnessmobileScreen,
  fitnessmobilshots,
  fitnessscreenshots,
  fitnessscreenshotspages,
} from "../../data";
const Scenario = () => {
  return (
    <div className="max-w-[1040px] w-[100%] m-auto">
      <p className="sm:text-[26px] text-[16px] mt-10 font-[700]">Touchpoints</p>
      <p className="sm:text-[22px] text-[16px] mt-2 font-[600] text-[#b3b3b3]">
        Scenerio 1
      </p>
      <ul className="list-disc ml-5 text-red-500 mt-2 font-[400] sm:text-[18px] text-[14px]">
        <li> User download and open app </li>
        <li>loading screen</li>
        <li>Onboarding Screens</li>
        <li>User is not logged in</li>
        <li>User creates account</li>
        <li>User gets confirmation email</li>
        <li> Onboarding screens </li>
        <li>User fills required information</li>
        <li>User view profile of other users nearby</li>
        <li>User swipes right on profiles</li>
        <li>User waits for confirmation </li>
        <li>He goes to dashboard.</li>
      </ul>
      <div className="grid grid-cols-12 mt-10">
        {fitnessmobile.map((item, index) => (
          <div className="col-span-2 mt-1 h-[100%]">
            <img src={item.mobile} alt="name" />
          </div>
        ))}
      </div>
      <p className="text-[22px] mt-10 font-[400] text-[#b3b3b3]">Scenario 2</p>
      <ul className="list-disc ml-5 text-[#FFC26B] mt-2 font-[400] sm:text-[18px] text-[14px]">
        <li>User gets a notification alert </li>
        <li>User opens app</li>
        <li>User click on notification icon</li>
        <li>See other users who wants to connect with him</li>
        <li>Swipes right on another user</li>
        <li>Recieves a celebratory message </li>
        <li>Can now chat with his buddy</li>
      </ul>

      <div className="grid grid-cols-12 mt-10">
        {fitnessmobileScreen.map((item, index) => (
          <div className="col-span-2 mt-1">
            <img src={item.mobile} alt="name" />
          </div>
        ))}
      </div>
      <p className="text-[22px] mt-5 font-[400] text-[#b3b3b3]">Scenario 3</p>
      <ul className="list-disc ml-5 text-green-600 mt-1 font-[400] sm:text-[18px] text-[14px]">
        <li>User logs in </li>
        <li>User wants to start exercise </li>
        <li>User clicks on the exercise icon</li>
        <li>User select the exercise type ( Cycling)</li>
        <li>User can see daily goals for exercise</li>
        <li>
          After selecting workout type, user will see the history from the last
          time he cycled
        </li>
        <li>User decides to play some music, click on the music icon</li>
        <li> User’s music collection opens, select songs and play</li>
        <li>Sets time, enable map</li>
        <li>Starts Cycling </li>
        <li> Pause exercise to buy some spinach from a store close to him</li>
        <li>Continues exercise</li>
        <li>User reaches exercise goal and gets a badge</li>
      </ul>
      <div className="grid grid-cols-12 mt-10">
        {fitnessmobilshots.map((item, index) => (
          <div className="col-span-2 mt-1">
            <img src={item.mobile} alt="name" />
          </div>
        ))}
      </div>
      <p className="text-[22px] mt-5 font-[400] text-[#b3b3b3]">Scenario 4</p>
      <ul className="list-disc ml-5 text-[#D124D4] mt-1 font-[400] sm:text-[18px] text-[14px]">
        <li> User is already signed in </li>
        <li>User clicks on diet plan</li>
        <li>User can see recommended meals for the day</li>
        <li> He doesnt like the breakfast and checks for an alternative</li>
        <li>User decides to add his own customized food to the menu</li>
        <li>User can see nutrients, macros and calories of each meal</li>
        <li> He adds image</li>
        <li> User tracks his meal for the day</li>
        <li>
          {" "}
          User wants to know the total number of nutients and calories he
          consumed for the day/week/month{" "}
        </li>
      </ul>
      <div className="grid grid-cols-12 mt-10">
        <div className="col-span-8 mt-1">
          <div className="grid grid-cols-12">
            {fitnessscreenshots.map((item, index) => (
              <div className="col-span-3">
                <img src={item.mobile} alt="name" />
              </div>
            ))}
          </div>
        </div>

        <div className="col-span-4 mt-1"></div>
      </div>

      <p className="text-[22px] mt-5 font-[400] text-[#b3b3b3]">Scenario 5</p>
      <ul className="list-disc ml-5 text-[#0D99FF] mt-1 font-[400] sm:text-[18px] text-[14px]">
        <li> User gets notification to check buddy’s progress </li>
        <li>User opens app </li>
        <li>Clicks on my buddy icon </li>
        <li>Views buddy’s weekly progress </li>
        <li>Leave feedbacks and reaction</li>
      </ul>
      <div className=""></div>
      <div className="w-[80%] mt-10">
        <div className="grid grid-cols-5">
          {fitnessscreenshotspages.map((item, index) => (
            <div>
              <img src={item.mobile} alt="name" className="w-50" />
            </div>
          ))}
        </div>

        {/* <div className="col-span-3 mt-1"></div> */}
      </div>

      <p className="text-[26px] mt-10 font-[700]">Conclusion:</p>
      <p className="sm:text-[18px] text-[14px] mt-1 font-[400]">
        <p>
          As earlier stated at the beginning of this project, this mobile app primarily focuses on people who wants 
          to stay fit and eat healthy but cannot due to some reasons. The app provides free support system in form 
          of a friend/buddy to help them through that journey and achieve the desired results. 
          During the course of this project, i gained new knowledge and learned new things about the human psychology 
          and behavior in this field of research.
          <br />
        </p>
        <p>
          <br />
          In a field trial with about 15 users, i found out that people get easily bored of a regimented 
          activities as time goes by and hence a decline in activities such as exercising. From research carried 
          out by me, i discovered that flexibility reduces drop off rate.
          <br /><br />
          90% of test users say they perform better when they are held accountable in any activities and 
          positive reinforcements keeps them motivated.
          <br /><br />
          At the end of this project,  realized a web based platform will be necessary in the future to show 
          better representation of tracked progress of the users and their buddies through graphs and other 
          data display methods.
          <br /><br />
        </p>
        <p>
          The major challenge was how to keep users engage with their goals and increase retention rate of the app. 
          Some of the solutions to these was to:
          <br />
        </p>
        <ul className="list-outside list-disc ml-5">
          <li>
            Send notification message to the user if no new progress data have been recorded in the last 3days and also 
            send a notification to the user’s buddy to check on the user after 7 days of inactivity and send some positive 
            reinforcement chat.
          </li>
          <li>
            When goals are met, the app allows users to set new goals, hence increasing retention rate of the app.
          </li>
        </ul>
      </p>
    </div>
  );
};

export default Scenario;
