import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
      display: false,
    },
    title: {
      display: false,
      text: "Usability Testing Results Comparison",
    },
  },
};

const labels = ["Usefulness", "Easy to Use", "Satisfaction"];

export const data = {
  labels,
  datasets: [
    {
      data: [68, 86, 82],
      backgroundColor: "#23395D",
      borderRadius: 10,
    },
    {
      data: [95, 80, 87],
      backgroundColor: "#6E5A7D",
      borderRadius: 10,
    },
    {
      data: [55, 75, 77],
      backgroundColor: "#59b2a9",
      borderRadius: 10,
    },
  ],
};
export default function ChartTable() {
  return <Bar options={options} data={data} />;
}
