import React from "react";

const Calabs1 = () => {
  return (
    <div className="max-w-[1040px] w-[100%] m-auto sm:px-5 px-6">
      <p className="font-[600] text-[18px] sm:text-[22px] mt-[20px]">
        Use Journey Map
      </p>
      <p className="sm:text-[18px] text-[14px] mt-1 font-[400]">
        Using the above touchpoints, i created the userjourney map.
      </p>
      <div className="flex justify-center items-center w-full h-full">
        <img
          src="/graph2.png"
          alt=" graph w-[1600px] "
          style={{ width: "90%" }}
        />
      </div>
    </div>
  );
};

export default Calabs1;
