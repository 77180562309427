import React from "react";
import {
  mobile,
  mobileScreen,
  mobilshots,
  screenshots,
  screenshotspages,
  homescreenshotspages
} from "../../data";
const Useablity = () => {
  return (
    <div className="max-w-[1040px] w-[100%] m-auto">
      <p className="font-[600] text-[18px] sm:text-[22px] mt-[24px]">Touchpoints</p>
      <p className="sm:text-[22px] text-[16px] mt-2 font-[600] text-[#b3b3b3]">
        Create Account: Scenario 1
      </p>
      <ul className="list-disc ml-5 text-red-500 mt-2 font-[400] sm:text-[18px] text-[14px]">
        <li>User downloads App</li>
        <li>User Opens App</li>
        <li>Onboarding Screens</li>
        <li>User is not logged in, user creates account</li>
        <li>User confirms email</li>
        <li>User fills in required information</li>
        <li>User verifies their identity</li>
        <li>User clicks on back to home button.</li>
      </ul>
      <div className="grid grid-cols-12 mt-10">
        {mobile.map((item, index) => (
          <div className="col-span-2 mt-1 h-[100%]">
            <img src={item.mobile} alt="name" />
          </div>
        ))}
      </div>
      <p className="sm:text-[22px] text-[16px] mt-10 font-[400] text-[#b3b3b3]">
        Swap Home (New User): Scenario 2
      </p>
      <ul className="list-disc ml-5 text-[#FFC26B] mt-2 font-[400] sm:text-[18px] text-[14px]">
        <li>User opens app (lands on home page)</li>
        <li>User clicks on the Swap home button</li>
        <li>User create their Listing</li>
        <li>User selects destination country/city</li>
        <li>User turns on the toggle button for visibility</li>
        <li>User clicks on the Swap home button to view homes</li>
        <li>User selects home</li>
        <li>User clicks on the request exchange button </li>
        <li>User can rate the servic</li>
        <li>User clicks on the back of the button </li>
      </ul>

      <div className="grid grid-cols-12 mt-10">
        {mobileScreen.map((item, index) => (
          <div className="col-span-2 mt-1">
            <img src={item.mobile} alt="name" />
          </div>
        ))}
      </div>
      <p className="sm:text-[22px] text-[16px] mt-5 font-[400] text-[#b3b3b3]">
        Swap Home (Existing User): Scenario 3
      </p>
      <ul className="list-disc ml-5 text-green-600 mt-1 font-[400] sm:text-[18px] text-[14px]">
        <li>User sign into their account</li>
        <li>User clicks on the Swap home button</li>
        <li>( for existing user, swap home button opens their listing)</li>
        <li>User can edit information on their listing</li>
        <li>User clicks on the update button</li>
        <li>User selects home</li>
        <li>User clicks on the request button</li>
        <li>User can rate the service</li>
        <li>User clicks on the back to home button.</li>
      </ul>
      <div className="grid grid-cols-12 mt-10">
        {mobilshots.map((item, index) => (
          <div className="col-span-2 mt-1">
            <img src={item.mobile} alt="name" />
          </div>
        ))}
      </div>
      <p className="sm:text-[22px] text-[16px] mt-5 font-[400] text-[#b3b3b3]">
        Book Home: Scenario 4
      </p>
      <ul className="list-disc ml-5 text-[#b3b3b3] mt-1 font-[400] sm:text-[18px] text-[14px]">
        <li>User clicks on the Book Home button </li>
        <li>User clicks on Find Home button to view homes</li>
        <li>User can search homes using available filters</li>
        <li>User selects home</li>
        <li>User clicks on the Request Booking button</li>
        <li> User rates service</li>
        <li> User clicks on the Go back home button.</li>
      </ul>
      <div className="grid grid-cols-12 mt-10">
        {screenshots.map((item, index) => (
          <div className="col-span-2 mt-1">
            <img src={item.mobile} alt="name" />
          </div>
        ))}
      </div>
      <p className="sm:text-[22px] text-[16px] mt-5 font-[400] text-[#b3b3b3]">
        Book Home (Become a host): Scenario 5
      </p>
      <ul className="list-disc ml-5 text-[#21C2F5] mt-1 font-[400] sm:text-[18px] text-[14px]">
        <li>User completes and submit listing</li>
        <li>User can click on the Become a Host button </li>
        <li>User creates listing</li>
        <li>User clicks on the Book Home button </li>
        <li>User’s Listing is visible to others.</li>
      </ul>
      <div className="grid grid-cols-12 mt-10">
        {screenshotspages.map((item, index) => (
          <div className="col-span-2 mt-1">
            <img src={item.mobile} alt="name" />
          </div>
        ))}
      </div>
      <p className="sm:text-[22px] text-[16px] sm:mt-5 mt-5 font-[600]">
        Other Screen
      </p>
      <div className="grid grid-cols-12 mt-2">
        <div className="col-span-8 mt-1">
          <div className="grid grid-cols-12">
            {homescreenshotspages.map((item, index) => (
              <div className="col-span-3">
                <img src={item.mobile} alt="name" />
              </div>
            ))}
          </div>
        </div>

        <div className="col-span-4 mt-1"></div>
      </div>
      <p className="font-[700] text-[18px] sm:text-[22px] mt-[20px]">
        Usability Testing:
      </p>
      <p className="sm:text-[18px] text-[14px] mt-1 font-[400]">
        This testing was carried out before the final implementation stage and
        10 users ranging from 21 to 60 years of age ( 5 online
        <br /> users and 5 family & friends) were contacted for this process.
        The aim of this testing was to understand how the users
        <br /> interact with the application and to improve and implement
        feedbacks for better user experience.
      </p>
    </div>
  );
};

export default Useablity;
