import React from "react";
import Footerbotton from "../../components/footerBottom";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Project from "../../components/projectDetail";
import { Link } from "react-router-dom";
// import Chartverticalbar from "../../components/chart"
const HomeSwap = () => {
  return (
    <React.Fragment>
      <Header />
      <div className="pt-16">
        <div
          className="h-100 w-full grid grid-cols-1   "
          style={{
            height: "100%",
            width: "vw%",
            backgroundSize: "cover",
            backgroundImage: "url(/homeswap.png)",
            objectFit: "contain",
            opacity: "0.9",
            backgroundSize: "contain",
          }}
        >
          <div
            style={{
              zIndex: "-5",
              background:
                "linear-gradient(90deg, rgba(198,78,48,0.7259278711484594) 0%, rgba(129,124,130,0.3925945378151261) 18%)",
              // background: "linear-gradient(90deg, rgba(198,78,48,0.7259278711484594) 0%, rgba(129,124,130,0.3925945378151261) 28%)",
              // background: "linear-gradient(90deg, rgba(198,78,48,0.7259278711484594) 1%, rgba(129,124,130,0.3925945378151261) 28%)"
              // background: "linear-gradient(90deg, rgba(198,78,48,0.7259278711484594) 2%, rgba(129,124,130,0.3925945378151261) 28%)"
              // background: "linear-gradient(90deg, rgba(198,78,48,0.7259278711484594) 3%, rgba(129,124,130,0.7819502801120448) 26%)"
              // background: "linear-gradient(90deg, rgba(198,78,48,0.7259278711484594) 0%, rgba(129,124,130,0.6643032212885154) 21%)"
              // background: "linear-gradient(90deg, rgba(198,78,48,0.7259278711484594) 2%, rgba(129,124,130,0.6643032212885154) 67%)"
              // background: "linear-gradient(90deg, rgba(198,78,48,0.7259278711484594) 6%, rgba(129,124,130,0.6643032212885154) 55%)"
              // background: "linear-gradient(90deg, rgba(198,78,48,0.7259278711484594) 11%, rgba(129,124,130,0.6643032212885154) 75%)"
              // background: "linear-gradient(90deg, rgba(198,78,48,0.7203256302521008) 25%, rgba(129,124,130,0.6643032212885154) 75%)"
              // background: "linear-gradient(90deg, rgba(92,81,85,0.7063200280112045) 25%, rgba(129,124,130,0.6643032212885154) 75%)",
              // background: "linear-gradient(90deg, rgba(92,81,85,0.8155637254901961) 25%, rgba(129,124,130,0.6643032212885154) 75%)",
              // background: "linear-gradient(90deg, rgba(92,81,85,0.6306897759103641) 25%, rgba(129,124,130,0.6643032212885154) 75%)",
              // background:"linear-gradient(90.07deg,rgba(235, 138, 115,0.6)0.07%, rgba(255,255,255,0.1) 42.2%, rgba(255,255,255,0.1)",
            }}
          >
            <div className="text-center sm:p-36 p-10">
              <h1 className="font-[700] justify-center flex text-white text-whiten text-[20px] sm:text-[40px]">
                Swap Home
              </h1>
              <p className="sm:text-2xl text-[14px] justify-center flex  font-[300] text-white">
                UI/UX Design Case study
              </p>
              <Link
                onClick={() =>
                  window.open(
                    "https://www.figma.com/file/ZZVoWsAvFIoWqDGvvVmOE1/Swap-Home-App?type=design&node-id=209%3A5501&t=BIrUNFo1v4qzgMRY-1",
                    "_blank"
                  )
                }
                className="bg-[#E88C77] font-semibold sm:text-[20px] text-[12px] justify-center flex  sm:mt-10 mt-6 m-auto border-2-[#E88C77] text-white sm:w-[200px] w-[158px]   rounded-xl py-4 "
              >
                View Prototype
              </Link>
            </div>
          </div>
        </div>
        <Project />

        <div className="justify-center items-center w-full flex sm:mt-32 mt-10">
          <img src="/76505 1.png" alt="img" className="w-full" />
        </div>
      </div>
      <Footer />
      <Footerbotton />
    </React.Fragment>
  );
};

export default HomeSwap;
