import React from "react";
import { fitnesprototypesmobile, swapData } from "../../data";

const fitnessprototype = () => {
  return (
    <div>
      <div>
        <div className="grid grid-cols-12">
          <div className="col-span-11">
            <div className="grid grid-cols-9">
              {fitnesprototypesmobile.map((item, index) => (
                <div className="mb-4">
                  <p className="ml-3 text-[12px] text-gray-400">{item.title}</p>
                  <img src={item.mobile} alt="graph" className="px-2" />
                </div>
              ))}
            </div>
          </div>
          <div className="col-span-1"></div>
        </div>
        <div className="grid grid-cols-10">
          {swapData.map((item, index) => (
            <div>
              <p className="ml-3 text-[12px] text-gray-400">{item.title}</p>
              <img src={item.mobile} alt="graph" className="px-2" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default fitnessprototype;
