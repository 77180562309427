import React from "react";

const Calabs = () => {
  return (
    <div className=" sm:px-5 px-3 pt-5 sm:pt-3">
      <div className="max-w-[1040px] w-[100%] m-auto sm:px-5 px-3  bg-[#f6f9ff]">
        <p className="sm:hidden block sm:text-[26px] text-[16px] mt-10 font-[700]">
          Personas 2:{" "}
        </p>
        <div className="grid grid-cols-12 sm:mt-10  sm:space-x-12 space-x-2 ">
          <div className="sm:col-span-4 col-span-12 mt-4 ">
            <div className="sm:bg-[#569EBD] bg-[#569EBD] p-3 rounded-lg w-fit">
              <img className="rounded-lg" src="/tim.png" alt="img " />
            </div>
          </div>
          <div className="sm:col-span-8 col-span-12 mt-4 w-full">
            {/* <p className="text-[#B3B3B3] sm:text-[22px]  text-[16px] font-[700]">
              User 2: Mubarak Jon 
            </p> */}
            <p className="text-[#B3B3B3] sm:text-[22px] text-[14px] font-[700]">
              <text className="font-bold text-zinc-900">Persona 2:</text> Mubarak Jon
            </p>
            <div className=" flex  items-center sm:text-[16px] text-[14px]">
              <p className="w-32">Age :</p>
              <p className="w-[16rem]">32yrs </p>
            </div>
            <div className=" flex  items-center sm:text-[16px] text-[14px]">
              <p className="w-32">Status :</p>
              <p className="w-[16rem]"> Married with kids</p>
            </div>
            <div className=" flex  items-center sm:text-[16px] text-[14px]">
              <p className="w-32">Occupation :</p>
              <p className="w-[16rem]">Back-end Developer</p>
            </div>
            <div className=" flex  items-center sm:text-[16px] text-[14px]">
              <p className="w-32">Location :</p>
              <p className="w-[16rem]">Amsterdam</p>
            </div>
            <div className=" flex  items-center sm:text-[16px] text-[14px]">
              <p className="w-32">Device Type :</p>
              <p className="w-[16rem]">Iphone</p>
            </div>
            <div className=" flex  items-center sm:text-[16px] text-[14px]">
              <p className="w-32">Goal(s):</p>
              <p className="w-[16rem]">Weight loss </p>
            </div>
            <div className=" flex  items-center sm:text-[16px] text-[14px]">
              <p className="w-32">Strength :</p>
              <p className="w-[16rem]">Organised, Hardworking, Patient </p>
            </div>
            <div className=" flex  items-center sm:text-[16px] text-[14px]">
              <p className="w-32">Weakness:</p>
              <p className="w-[16rem]">Busy</p>
            </div>
          </div>
        </div>
        <p className="sm:text-[22px] text-[16px] mt-5 font-[600] ">
          Behaviours:
        </p>
        <ol className="sm:text-[18px] text-[14px] mt-2">
          <p>
            i) Wakes up 6am daily, drops off the kids in school and hurry to
            catch the train to work everyday
            <p>ii) Sits for most of the day behind desk working</p>
            <p>
              iii) Spends his weekends playing flight stimulator on pc and
              entertaining/babysitting his kids
            </p>
            <p>
              iv) The only exercise he gets is when he cycles on his bicycle to
              get groceries from the store
            </p>
            <p>v) Worry about his sedentary lifestyle</p>
            <p>
              {" "}
              vi) He has been off and on his commitment to the gym and is in
              need of a different approach
            </p>
            <p>vii) Concern about his eating habits</p>
            <p>
              {" "}
              viii) Gets high energy when he worksout in his dorm room with his
              friends
            </p>
            <p>
              ix) Would love to workout at home but confused on how to start{" "}
            </p>
          </p>
        </ol>
        <p className="sm:text-[18px] text-[14px] mt-10 font-[400]">
          From interviews carried out for this target group, i was able to
          deduct and understand their behavior and lifestyle and <br />
          created one persona from this group to sum them up. From my research
          data, to further expasiate on what this users
          <br />
          <span className="font-bold">said, did, thought</span> and{" "}
          <span className="font-bold">felt,</span> i used an{" "}
          <span className="font-bold">empathy map.</span>
        </p>
        <div className="border-[1px] mt-10 border-[#569EBD] rounded-[50px]">
          <div className=" relative grid grid-cols-12  ">
            <div className="col-span-6 border-[#569EBD] border-r  border-b">
              <div className=" items-center py-5 px-4 sm:h-auto my-boxes2">
                <p className=" text-center justify-center font-[700] sm:text-[22px] text-[16px]">
                  Said:
                </p>
                <ol className="   font-[400] sm:text-[18px] text-[14px] mb-4">
                  <p>
                    i) Between work and family, i have very little time left
                    can’t push myself to go to start
                  </p>
                  <p>
                    ii) After a hectic day at work, i want to come home and
                    sleep
                  </p>
                  <p>
                    iii) I have taken the first step of registering to a gym but
                    i just
                  </p>
                  <p>
                    iv) I would love to workout at home, but i just don’t know
                    how
                  </p>
                  <p>
                    v) I wish i can get someone who understands my situation and
                    can motivate me I am concerned about my eating habits, i
                    wish i can do better
                  </p>
                </ol>
              </div>
            </div>
            <div className="col-span-6 border-[#569EBD]  border-b ">
              <div className="  py-5  px-4 my-boxes2">
                <p className="font-[700] text-center  sm:text-[22px] text-[16px]">
                  Did
                </p>
                <ol className="  font-[400] sm:text-[18px] text-[14px]">
                  <p>
                    i) Registers to a nearby gym, went twice and stopped uses
                    them
                  </p>
                  <p>ii) Buys some affordable gyming equipment, rarely</p>
                  <p>
                    iii) Started eating healthy for some time, felt good but not
                    consistent with it
                  </p>
                  <p>
                    iv) Got alot of motivation, subscribed to some fitness
                    channels on youtube, followed a few times and stopped
                  </p>
                </ol>
              </div>
            </div>
            <div className=" col-span-6 border-[#569EBD] border-r">
              <div className="  py-5  px-4  my-boxes22">
                <p className=" text-center justify-center items-center font-[700] sm:text-[22px] text-[16px]  mt-4">
                  Thought:
                </p>
                <ol className="   font-[400] sm:text-[18px] text-[14px] mt-4">
                  <p>i) Keeping up with workout routines is so stressful</p>
                  <p>ii) I can only workout on saturdays</p>
                  <p>iii) I need alot of food to get me through busy days</p>
                  <p>
                    iv) I need someone to motivate and push me to achieve my
                    health goals
                  </p>
                </ol>
              </div>
            </div>
            <div className="col-span-6 border-[#569EBD] ">
              <div className="  py-5  px-4 my-boxes22">
                <p className=" text-center justify-center font-[700] sm:text-[22px] text-[16px] mt-4">
                  Felt
                </p>
                <ol className="   font-[400] sm:text-[18px] text-[14px] mt-4">
                  <p>i) Always tired</p>
                  <p>ii) Diet planning apps are so unrealistic</p>
                  <p>
                    iii) It will take alot of effort and time to get me to my
                    ideal body type
                  </p>
                  <p>
                    iv) Self conscious around friends/ colleagues of the same
                    age
                  </p>
                </ol>
              </div>
            </div>
            <div className="absolute lg:left-[43%] lg:top-[47%] md:left-[41%] md:top-[49%] sm:left-[41%] sm:top-[49%] left-[32%] top-[49.5%] my-circle pt-[20px]
             border-[#569EBD] border-2 rounded-full sm:h-[141px] sm:w-[141px] h-[100px] w-[100px] p-2">
              <p className="text-[#569EBD] text-center sm:text-[24px] text-[16px] font-[700]">
                {" "}
                User 2:
              </p>
              <p className=" sm:text-[22px] text-[16px] text-center font-[700]">
                Mubarak Jon
              </p>
            </div>
          </div>
        </div>
        <p className="sm:text-[18px] text-center sm:my-5 mb-5 mt-1 font-[400] text-[#B3B3B3]">
          Empathy Map 2
        </p>
        <p className="sm:text-[22px] text-[16px] mt-5 font-[600]">
          Mubarak’s Needs:
        </p>
        {/* <p className="sm:mt-20 mt-5 font-[700] text-[26px]">Mubarak’s Needs:</p> */}
        <ol className="   font-[400] sm:text-[18px] text-[14px] mt-4">
          <p> i) A diet planning app</p>
          <p>ii) A more active lifestyle</p>
          <p>iii) A workout routine app that allows for flexible hours</p>
          <p>iv) A personalized fitness routine</p>
          <p>v) A buddy to keep him constantly motivated</p>
          <p>vi) An app that provides proper guidiance</p>
          <p>vii) To be able to track and monitor his progress</p>
        </ol>
      </div>
    </div>
  );
};

export default Calabs;
