import {React, useState } from "react";

const Footer = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleName = event => setName(event.target.value);
  const handleEmail = event => setEmail(event.target.value);
  const handleMessage = event => setMessage(event.target.value);
  const constructEmail = () => {
    return "mailto:victoria.eneji1+uxmail@gmail.com?subject=" + 
            encodeURIComponent(`Message from ${name}`) + "&body=" +
            encodeURIComponent(`${message}` + "\n\n")  + `Yucan reply to me at ${email}`;
  }
  
  return (
    <div id="footer" className="sm:mt-[84px] mt-[0px]  ">
      <form className="bg-[#f3f3f3] ">
        <div className=" sm:px-16 px-5 max-w-[1253px] w-[100%] m-auto  ">
          <p className=" sm:pt-[58px] pt-[26px] md:text-[26px] text-[14px] font-[400] font-sans leading-[150%] tracking-tight">
            Want to get in touch?
            <br /> Leave me a message!
          </p>
          <div className="grid grid-cols-12 space-x-4 md:mt-[50px] mt-[20px]">
            <div className="md:col-span-4 col-span-6">
              <p className="md:text-[18px] text-[9px] font-[600] text-black">
                NAME
              </p>
              <div>
                <input
                  type="text"
                  id="first_name"
                  className="bg-gray-50  sm:mt-3 mt-2 border  indent-3
                   border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter your full name"
                  required
                  onChange={handleName}
                />
              </div>
            </div>
            <div className="md:col-span-4 col-span-6">
              <p className="md:text-[18px] text-[9px] font-[600] text-black">
                EMAIL ADDRESS
              </p>
              <div>
                <input
                  type="text"
                  id="first_name"
                  className="bg-gray-50 sm:mt-3 mt-2 indent-3  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter your email"
                  required
                  onChange={handleEmail}
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-12  sm:mt-[40px] mt-[10px]">
            <div className="md:col-span-8 col-span-12 ">
              <p className="md:text-[18px] text-[9px] font-[600] text-black">
                MESSAGE
              </p>
              <div>
                <textarea
                  id="message"
                  rows="3"
                  className="block indent-3 sm:mt-3 mt-2 p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter your message"
                  onChange={handleMessage}
                ></textarea>
              </div>
              <div className="">
                <input className="bg-black mt-5 md:text-[22px]  text-[12px]  mb-10 py-4 px-9 text-white rounded-xl border-2
                                  hover:cursor-pointer"
                type="submit"
                formAction={constructEmail()} />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Footer;
