import React from "react";
import { icon } from "../../data";
import Personas from "../../components/personas";
import Calabs from "../../components/calabs";
import Scenario from "../../components/scenario";
import Empathize from "../../components/empathize";
const Problem = () => {
  return (
    <React.Fragment>
      <div className="sm:px-5 px-3">
        <div className="grid grid-cols-1 sm:px-5 px-3">
          <div className="max-w-[1556px] w-[100%] m-auto px-5 lg:px-28 bg-white">
            <p className="font-[700] sm:text-[26px] text-[16px] sm:mt-[103px] mt-[15px]">
              {" "}
              The Problem
            </p>
            <p
              className="font-[400] sm:text-[18px] text-[14px] sm:mt-[20px] mt-1"
              style={{ lineHeight: "27px" }}
            >
              The year 2020 saw a major increment in the number of people who
              started working out from their homes, in attempts to keep fit and
              stay healthy during the months long lockdown. This birthed a lot
              of fitness routine apps and diet planning apps. Just like some
              things, the novelty of it worn-out and some people lost interest
              because:
            </p>
            <ol className="list-decimal mt-6 sm:text-[18px] text-[14px]">
              <p>i) They just couldn’t keep up. </p>
              <p>ii) Lack of motivation.</p>
              <p>iii) Work schedules took its toil.</p>
            </ol>
            <p className="font-[400] sm:text-[18px] text-[14px] mt-[32px]">
              {" "}
              Hence, the <span className="font-bold">Retention rate</span> in
              these application keeps decreasing because of less engagement by
              users.
            </p>
            <p className="font-[700] sm:text-[26px] text-[16px] mt-[13px]">
              {" "}
              Solution:
            </p>
            <p
              className="font-[400] sm:text-[18px] text-[14px]  mt-[13px] "
              style={{ lineHeight: "27px" }}
            >
              Personal Trainers are also motivators who collaborate with clients
              to set goals and reach those set goals. Now, not everyone can
              afford to go to the Gym or employ the services of a personal
              trainer and for those who can afford the Gym but not the personal
              trainer, according to statistics, a lot of them lose motivation
              and eventually stop going.
              <br />
              <span className="font-bold">
                Introduction of Fitness Buddies: <br />
              </span>
              Now Fitness buddies is a mobile application that shows users
              profile of other users within a certain radius and it allows users
              swipe right and connect with another user who have similar goals
              and also someone they think can push them to meet their set goals
              and verse versa with the following features:
            </p>
            <ol className="list-decimal sm:text-[18px] text-[14px]">
              <p>i) Chats session </p>
              <p>ii) Diet plan and Calorie count</p>
              <p>
                iii) Routine Workouts and Track you and your buddy’s progress
              </p>
              <p>iv) Set Timers and Reminders</p>
              <p>v) Select Workout plan (Progressive)</p>
              <p>vi) View Workout profile of your buddy</p>
              <p>vii) Share motivational music with your buddy</p>
              <p>viii) Drop feedback/reaction</p>
            </ol>

            <p className="font-[700] smLtext-[26px] text-[16px] mt-[32px]">
              Target Audience:
            </p>
            <p className=" font-[400] sm:text-[22px] text-[14px] mt-[5px]">
              18-24 year old's:
            </p>
            <ul className="list-disc  sm:text-[18px] text-[14px]">
              <p>
                Most users under this age group are college students who are
                keen about working out and keeping fit but:
              </p>
              <ol className="list-decimal sm:text-[18px] text-[14px]">
                <p>
                  {" "}
                  i) Can not afford to register to a Gym for financial reasons
                </p>
                <p>
                  ii) Gets high energy when working out with someone, especially
                  someone of the same age group{" "}
                </p>
              </ol>
            </ul>
            <p className=" font-[400] sm:text-[22px] text-[16px] mt-[5px]">
              25-45 year old’s:
            </p>
            <ul className="list-disc  sm:text-[18px] text-[14px]">
              <p>
                Users under this group are professionals/working adults who
                understands the importance and benefits of working
                out/exercising, conscious of food they take into their bodies,
                having a routined diet plan and made several attempts at it but
                somehow don’t follow through.
              </p>
              <p className="mt-10 sm:text-[18px] text-[14px]">
                To further understand the selected target audience, Here are
                some few thoughts and questions i pondered on:
              </p>
              <ol className="list-decimal sm:text-[18px] text-[14px]">
                <p>
                  {" "}
                  i) What are the primary and secondary reasons why they want to
                  workout/exercise?
                </p>
                <p> ii) What are their set goals? </p>
                <p>
                  {" "}
                  iii)Do they stop exercising when they don’t see results?{" "}
                </p>
                <p> iv) Do they stop exercising when they see results?</p>
                <p>
                  {" "}
                  v) How do they feel after missing several sessions?, Do they
                  feel urge to do better?{" "}
                </p>
                <p> vi) Do they feel discouraged and give up altogether? </p>
                <p>
                  vii) Would they get more energy when they have someone
                  constantly check on their progress and encourage them to do
                  better/keep it up.
                </p>
              </ol>
            </ul>
            <p className="font-[700] sm:text-[26px] text-[16px] mt-[32px]">
              User Research:
            </p>
            <ul className="list-disc  sm:text-[18px] text-[14px]">
              <p>
                For Research method, i decided to go with user interview
                method (qualitative research method) to better understand
                the goals, lifestyle, difficulties of the target audience and
                also what motivates/would motivate them to keep up with their
                workout plans.
              </p>
              <p className="mt-10 sm:text-[16px] text-[14px]">
                Here are a few of the questions asked:
              </p>
              <ol className="list-decimal sm:text-[18px] text-[14px]">
                <p>
                  {" "}
                  i) What are the primary and secondary reasons why you want to
                  workout/exercise?{" "}
                </p>
                <p>ii) How often to you work out in a week? </p>
                <p>iii) How many hours a week do you spare? </p>
                <p>iv) Do you prefer working out at home or in the gym? </p>
                <p>
                  v) Would you use a workout/exercise app that also helps you
                  with diet planning?{" "}
                </p>
                <p> vi) What are the focused areas for you? </p>
                <p>
                  vii) Do you think a workout plan focused on these areas would
                  help?{" "}
                </p>
                <p>viii) Do you stop exercising when you don’t see results? </p>
                <p>ix) Do you stop exercising when you see results? </p>
                <p>
                  {" "}
                  x) Do you think you will be motivated to continue, if you have
                  someone to workout with, who constantly checks on your
                  progress?
                </p>
                <p> xi) What do you think would motivate you? </p>
                <p>
                  {" "}
                  xii) What features would want to have in a fitness app?
                  <br />
                  <p className="mt-10 sm:text-[16px] text-[14px]">
                    Three people from each target audience were interviewed
                    using the questions above.
                    <br /> The purpose of this was to get more information and
                    answers to thoughts i had pondered upon earlier and to
                    further <br /> create an app that is tailored to proffering
                    solutions to the challenges and needs for the users. Summary
                    of interview:{" "}
                  </p>{" "}
                </p>
              </ol>
            </ul>
            <ul className="list-disc  text-[18px]">
              <p className=" font-bold sm:text-[22px] text-[16px] mt-10">
                Summary of interview:
              </p>
              <ol className="list-decimal sm:text-[18px] text-[14px]">
                <p>
                  {" "}
                  i){" "}
                  <span className="ml-3">
                    {" "}
                    Not enough time left in the day during week days for people
                    to engage in extra curricular activities after work, hence
                    60 <br className="ml-5" /> percent of the people interviewed
                    prefer to workout on weekends
                  </span>{" "}
                </p>
                <p>
                  ii){" "}
                  <span className="ml-3">
                    {" "}
                    The feeling of isolation and loneliness discourages most
                    people from working out at their own connivences at home and
                    <br /> are also not open to signing up to crowded gyms, but
                    70 percent of the people interviewed are open to working out
                    <br /> from their homes if they have an app that connects
                    them with someone in similar situation who can motivate
                    them.
                  </span>
                </p>
                <p>
                  iii){" "}
                  <span className="ml-3">
                    {" "}
                    From interviews conducted, 70 percent of people stop working
                    out when they don’t see results for almost one month and
                    <br /> 50 percent of people interview, stop working out when
                    they see results. Hence, an app that shows weekly progress
                    to keep <br />
                    the user going and an app that allows the user set new goals
                    if the set goals are met.{" "}
                  </span>{" "}
                </p>
                <p>
                  {" "}
                  iv)
                  <span className="ml-3">
                    {" "}
                    Realistic diet plans to encourage users continue the process
                    and gradually meet their goals.
                  </span>
                </p>
                <p>
                  {" "}
                  v)
                  <span className="ml-3">
                    {" "}
                    Most users want a workout plan that employs the use of
                    simple and affordable gyming tools for those who prefer to
                    <br /> workout from home.
                  </span>
                </p>
                <p>
                  {" "}
                  vi)
                  <span className="ml-3">
                    {" "}
                    Workout routines that gradually easy the users in.
                  </span>
                </p>
              </ol>
            </ul>
          </div>
        </div>
      </div>
      <p className="max-w-[1040px]  m-auto text-[24px] mt-10 font-[700] text-zinc-500 px-5 xl:px-[0px]">
        Personas:
      </p>
      <Personas />
      <Calabs />

      <div className="px-5 xl:px-0 max-w-[1040px] w-[100%] m-auto">
        <p className="sm:text-[26px] text-[16px] mt-10 font-[700]">
          User Journey Map
        </p>
        <p className="sm:text-[18px] text-[14px] mt-1 font-[400]">
          Using the above touchpoints, i created the userjourney map.
        </p>
      </div>
      <div className="  sm:px-5 px-3 max-w-[1040px] w-[100%] m-auto">
        <div className="  sm:px-5 px-3">
          <div className="flex justify-center items-center w-full h-full">
            <img
              src="/logntable.png"
              alt=" graph w-[1600px] "
              style={{ width: "90%" }}
            />
          </div>
          <p className="sm:text-[26px] text-[16px] mt-10 font-[700]">
            Information Architecture:
          </p>
          <p className="sm:text-[18px] text-[14px] mt-1 font-[400]">
            I used the card sorting system to understand the users perception
            and how they would interact with the features of the app.
            <br /> For this research, i used the Miro tool, created the cards
            and shared with five(5) users that falls under the target audience.
            With
            <br /> this, i was able to group items/features according to the
            sections they fit better.
          </p>
          <div className="flex justify-center items-center mt-10">
            <img src="/table1.png" alt="image" className="w-full" />
          </div>
          <p className="flex justify-center items-center text-center mt-10 sm:text-[18px] text-[14px] font-[400] text-[#B3B3B3]">
            Image 1: Showing cards to be sorted
          </p>
          <div className="flex justify-center items-center mt-10">
            <img src="/table2.png" alt="image" className="w-full" />
          </div>
          <p className="flex justify-center items-center text-center mt-10 sm:text-[18px] text-[14px] font-[400] text-[#B3B3B3]">
            Image 2: Showing cards grouped into sections
          </p>
          <p className="sm:text-[26px] text-[16px] mt-5 font-[700]">
            Prototype:
          </p>
          <p className="sm:text-[22px] text-[14px] mt-4 font-[600]">
            Low and High Fidelity Wireframe:
          </p>
          <p className="sm:text-[18px] text-[14px]  font-[400]">
            {" "}
            Using the touchpoints, i designed the low fidelity wireframe with
            four different scenarios to better understand each activities the
            users have chosen to perform and goals the users wants to achieve.
          </p>
          <Scenario />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Problem;
