import React from "react";

const Footerbotton = () => {
  return (
    <div>
      <div className="sm:px-[80px] px-[20px] my-4 max-w-[1700px] m-auto">
        <div className="grid grid-cols-12 ">
          <div className="col-span-10">
            <img src="/VO.svg" alt="img" className="h-[22px] w-[28px]" />
          </div>
          <div className="col-span-2  ">
            <div className="flex  justify-end space-x-4">
              <img
                src="/Group.png"
                alt="img"
                className="cursor-pointer h-[20px] w-[24px]"
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/in/victoria-onogu/",
                    "_blank"
                  )
                }
              />
              <a href={`mailto:victoria.eneji1@gmail.com`}>
                <img
                  src="/Vector.png"
                  alt="img"
                  className="h-[20px] w-[24px] cursor-pointer"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footerbotton;
