import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
const link = [
  { name: "HOME", path: "/" },
  { name: "ABOUT", path: "/about" },
  // { name: "CONTACT", path: "/footer" },
];
const linkmenu = [
  { name: "HOME", path: "/" },
  { name: "WORK", path: "/" },
  { name: "Fitness Buddies", path: "/Fitness" },
  { name: "Swap Home", path: "/homeswap" },
  { name: "RevPro", path: "/rev-pro" },
  { name: "ABOUT", path: "/about" },
];

const Header = () => {
  const [manu, setManu] = useState(false);
  const { postId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  console.log("hlo  post id", postId);
  // const HandleManu = () => {
  //   setManu(true);
  // };
  // console.log("###", location.pathname);
  const handleRoadeMap = () => {
    const element = document.getElementById("footer");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <React.Fragment>
      <div className="w-[100%] py-[25px] sm:px-[80px] px-[15px] fixed bg-white z-20">
        <div className="container m-auto">
          <div className="flex justify-between">
            <div className="lg:col-span-8 md:col-span-6 sm:col-span-4 col-span-12">
              <Link to="/">
                <img src="/VO.svg" alt="" />
              </Link>
            </div>
            <div className="lg:col-span-4 md:col-span-4 sm:col-span-8 sm:block hidden">
              <div className="flex justify-between space-x-8">
                {link.map((item, index) => (
                  <div key={index}>
                    <p
                      // to={item.path}
                      onClick={() => {
                        navigate(item.path);
                        window.scrollTo(0, 0);
                      }}
                      className={`font-[400]  text-[20px] cursor-pointer ${
                        location.pathname === item.path ? "" : "text-[#D9D9D9]"
                      }`}
                    >
                      {item.name}
                    </p>
                  </div>
                ))}
                <Link
                  onClick={handleRoadeMap}
                  className="font-[400] text-[20px]
                      text-[#D9D9D9]"
                >
                  CONTACT
                </Link>
              </div>
            </div>
          </div>
          <div className=" sm:hidden absolute top-6 right-9">
            <img
              onClick={() => setManu(!manu)}
              src="/Component.png"
              alt="manu"
            />
          </div>
          {manu ? (
            <div className="bg-white z-30 h-[70%] top-0 w-full  right-0 ">
              <div className="justify-center text-center px-5">
                {linkmenu.map((item, index) => (
                  <div key={index} className="py-1   mt-2">
                    <p
                      // to={item.path}
                      onClick={() => navigate(item.path)}
                      className={` font-[400]  text-[13px] cursor-pointer ${
                        index !== 0 &&
                        index !== 1 &&
                        index !== linkmenu.length - 1
                          ? " text-[#0D99FF]"
                          : ""
                      }`}
                    >
                      {item.name}
                    </p>
                  </div>
                ))}
                {/* <svg
                onClick={() => setManu(!manu)}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6 mr-3 absolute  right-5 top-[90px]"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg> */}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Header;
