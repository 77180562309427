import React from "react";

const Empathize = () => {
  return (
    <div className="max-w-[1040px] w-[100%] m-auto">
      <p className=" mt-[2px] sm:text-[18px] text-[14px]">
        This empathy stage was the first step to understand who really are the
        target users, what are their needs, what they would like to see in this
        platform and what they would want to accomplish during/after using the
        platform.
        <br />
        The Target users is divided into mainly two categories:
      </p>
      <ol className="list-decimal mt-3 sm:text-[18px] text-[14px]">
        <p> i) The Home Owner </p>
        <p>ii) The Guest.</p>
      </ol>
      <p className="sm:text-[18px] text-[14px] mt-3 font-[400]">
        To further elaborate on the perspective of the{" "}
        <span className="font-bold"> Home Owner,</span> here are some of the
        thoughts and questions i pondered on:
        <ul className="list-disc ml-5 mt-3 font-[400] sm:text-[18px] text-[14px]">
          <li>
            How does it profit the home owner to give out their homes to guests
            for some period of time?
          </li>
          <li>
            How can the home owner trust that their homes will not be raided by
            the occupants?
          </li>
          <li>
            How can the home owner enforce the rules of his/her home in their
            absence?
          </li>
          <li>
            In case of destruction of property, what are the policies protecting
            the owner?
          </li>
          <li>
            For short lenting, how does the home owner determine cost (per
            night, discounts etc)?
          </li>
          <li>
            How can we create a platform that is user centric, less cumbersome
            and easy for the home owner/user to navigate through?.
          </li>
        </ul>
      </p>
      <p className="sm:text-[18px] text-[14px] mt-3 font-[400]">
        For <span className="font-bold">The Guest</span>
        <ul className="list-disc ml-5 mt-3 font-[400] sm:text-[18px] text-[14px]">
          <li>
            What measures should be put in place to ensure the safety of guests?
          </li>
          <li>
            What happens if the amenities listed by home owner isn’t available?
          </li>
          <li>
            How can we optimize results that is tailored to the
            needs/requirements of guest?
          </li>
          <li>
            How can we create a platform that is user centric, less cumbersome
            and easy for the guest/user to navigate through?.
          </li>
        </ul>
      </p>
      <p className="font-[600] text-[18px] sm:text-[22px] mt-[20px]">
        User Research:
        <p className="sm:text-[18px] text-[14px] mt-[2px] font-[400]">
          For this Research, Interview was conducted. Eight people (8) who fit
          into the two target group were interviewed. Three Home Owners and Five
          potential Guests. The purpose of this interview was to get more
          information directly from home owners and potential guests to better
          understand the challenges, worries and needs of the target users.
          <br />
          Below are some of the questions asked:
        </p>
        <ul className="list-disc ml-5 mt-3 font-[400] sm:text-[18px] text-[14px]">
          <li>
            On a scale of 0-5, with 5 been the highest, how open are you to
            allow guests into your home for a period of time?
          </li>
          <li>
            As a guest, what safety measures would you like the home to have?
          </li>
          <li>As a guest, what amenities are a must have?</li>
          <li>
            Are you more open to lenting a home for your vacation or a hotel?
          </li>
          <li>Would you allow guests with their pets?</li>
          <li>What type house would you prefer for your vacation and why?</li>
          <li>What feature would you like to see in a home swap platform?</li>
          <li>
            As a home owner, what policy do you think would help protect your
            properties?
          </li>
        </ul>
      </p>
      <p className="sm:text-[18px] text-[14px] mt-3 font-[400]">
        From the Interview conducted during the research process, here are some
        concerns/needs from the target users:
      </p>

      <ul className="list-disc ml-5 mt-3 font-[400] sm:text-[18px] text-[14px]">
        <li>Is the house in a safe neighborhood?</li>
        <li>What are the cancellation policies?</li>
        <li>What are the late minute reservation policies?</li>
        <li>
          For the weather condition, will they be any air conditioning or
          heating system in the house?
        </li>
        <li>Are guest allowed to cook in the house?</li>
        <li>
          Home owners would like to know a little more about the guests who will
          be occupying their home.
        </li>
        <li>
          In case of an emergency, how can the guests exit the building and who
          should they contact?
        </li>
        <li>
          How can they hold the guests liable for destroy or damages on
          property?
        </li>
        <li>How can they report a difficult host or guest?</li>
      </ul>

      <p className="font-[600] text-[18px] sm:text-[22px] mt-[20px]">
        Define:
        <p className="sm:text-[18px] text-[14px] mt-[2px] font-[400]">
          From the Interview conducted, here are some of my findings:
        </p>
        <ul className="list-disc ml-5 mt-3 font-[400] sm:text-[18px] text-[14px]">
          <li>
            60% of home owners i interviewed, are open to short lenting their
            home for some money.
          </li>
          <li>
            90% of potential guest interviewed are open to using swap home app
            as long as there are no hidden charges.
          </li>
          <li>
            90% of the people interviewed, would prefer an app that has both the
            booking and swapping features to avoid the stress of using multiple
            apps.
          </li>
          <li>
            In case of lack in internet service, an app that allows them access
            to view their reservation locally.
          </li>
          <li>
            Both the home owners and the guests wants to feel safe, hence, extra
            identity verification is required.
          </li>
          <li>
            Users want an affordable place where they can enjoy their vacation
            with family and friends.
          </li>
          <li>Users want a place that would feel like home away from home.</li>
          <li>Users want the flexibility to cancel reservations.</li>
          <li>For easy accessibility, users want a mobile based app.</li>
        </ul>
      </p>

      <div
        className="sm:px-5 px-2 sm:py-10 py-5 mt-5 w-full rounded-lg"
        style={{
          background: "linear-gradient(270deg, #EB8A73 0%, #415C7C 100%)",
        }}
      >
        <p className="sm:px-20 px-2 text-white  text-[22px] font-[700]">
          Problem Statement:
        </p>
        <p className="sm:px-20 px-2 pt-22 text-white sm:text-[18px] text-[14px] font-[400]">
          Travelers/Tourists who likes the experience of a home away from home,
          needs a more cost effective approach to enjoy convenience with family
          and friends and home owners who are low or average income earners, who
          wants to make some income when they are away from home.
        </p>
      </div>
      <p className="text-[24px] mt-10 font-[400]">
        {/* Solution */}
        <p className="sm:text-[18px] text-[14px] mt-3 font-[400]">
          Here are some of the solutions to the concerns and needs of the users:
        </p>
        <ul className="list-disc ml-5 mt-2 font-[400] sm:text-[18px] text-[14px]">
          <li>A mobile based application for easy access.</li>
          <li>
            An app with an offline feature, where users can still get access to
            their reservation without the internet.
          </li>
          <li>An app with both the book home and swap home feature.</li>
          <li>
            In case of lack in internet service, an app that allows them access
            to view their reservation locally.
          </li>
          <li>
            An app with filters such as price filter to optimize users results
            and cater for all.
          </li>
          <li>
            By creating an app that connects travelers around the world who are
            willing to exchange their homes at no cost at all, hence, making
            vacationing cost effective.
          </li>
        </ul>
      </p>
    </div>
  );
};

export default Empathize;
